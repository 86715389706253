<template>
  <CategoryTop
    banner-image="trainings-overview.jpg"
    small
    title="Mein Postfach"
    :breadcrumbs-parent-routes="['account']"
    description="Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Stammdaten ergänzen oder anpassen, Ihr Postfach und Ihren Punkte-Kontosauszug einsehen."
  />

  <div v-if="mailReaderKey !== false" class="mail-reader position-relative">
    <div class="justify-content-end d-flex buttons-container">
      <button
        type="button"
        class="btn font-18 py-1"
        @click="openMailReader(mailReaderKey + 1)"
      >
        <MDBIcon icon="chevron-left" size="1x" />
      </button>
      <button
        type="button"
        class="btn font-18 py-1"
        @click="openMailReader(mailReaderKey - 1)"
      >
        <MDBIcon icon="chevron-right" size="1x" />
      </button>
      <button type="button" class="btn font-18 py-1" @click="mailReaderKey = false">
        <MDBIcon icon="times-circle" size="1x" />
      </button>
    </div>
    <MDBRow class="sender-row">
      <MDBCol sm="3" xl="1">
        <p class="fw-bold mb-0 mb-sm-3">Absender:</p>
      </MDBCol>
      <MDBCol sm="9" xl="11">
        <p class="">{{ mails[mailReaderKey].sender }}</p>
      </MDBCol>
    </MDBRow>
    <MDBRow class="topic-row">
      <MDBCol sm="3" xl="1">
        <p class="fw-bold mb-0 mb-sm-3">Betreff:</p>
      </MDBCol>
      <MDBCol sm="9" xl="11">
        <p class="">{{ mails[mailReaderKey].topic }}</p>
      </MDBCol>
    </MDBRow>
    <div v-html="mails[mailReaderKey].content" class="mail-reader-content"></div>
  </div>

  <div v-else>
    <MDBRow class="mail-header justify-contend-between d-flex">
      <MDBCol col="1" class="checkbox-col">
        <input
          class="mb-0 form-check-input"
          v-model="checkAllCheckbox"
          @click="toggleCheckAll()"
          type="checkbox"
          name="checkbox"
        />
      </MDBCol>
      <MDBCol col="3" class="sender-col">
        <p class="mb-0 fw-bold d-none d-md-block">Absender</p>
      </MDBCol>
      <MDBCol col="6" class="topic-col d-none d-md-block">
        <p class="mb-0 fw-bold">Betreff</p>
      </MDBCol>
      <MDBCol col="2" class="date-col d-none d-md-block">
        <p class="mb-0 fw-bold">Datum</p>
      </MDBCol>
    </MDBRow>

    <MDBRow
      v-for="(mail, key) in mails"
      v-bind:key="key"
      class="mail-row justify-contend-between d-flex"
    >
      <MDBCol md="1" xs="2" class="checkbox-col order-1">
        <input
          class="mb-0 form-check-input"
          v-model="checkboxes[key]"
          type="checkbox"
          name="checkbox"
        />
      </MDBCol>
      <MDBCol
        md="3"
        xs="6"
        :class="`sender-col order-3 order-md-2 ${mail.unreaded ? 'fw-bold' : ''}`"
        @click="openMailReader(key)"
      >
        {{ mail.sender }}
      </MDBCol>
      <MDBCol
        md="6"
        xs="6"
        :class="`topic-col order-4 order-md-3 ${mail.unreaded ? 'fw-bold' : ''}`"
        @click="openMailReader(key)"
      >
        {{ mail.topic }}
      </MDBCol>
      <MDBCol
        md="2"
        xs="6"
        :class="`date-col order-2 order-md-4 ${mail.unreaded ? 'fw-bold' : ''}`"
        @click="openMailReader(key)"
      >
        {{ mail.date }}
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol>
        <button class="btn btn-danger mt-4" @click="deleteSelected()">löschen</button>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { ref } from "vue";

const mails = ref([
  {
    sender: "Mail Author",
    topic: "Mail Topic",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author2",
    topic: "Mail Topic2",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author3",
    topic: "Mail Topic3",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author4",
    topic: "Mail Topic4",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author5",
    topic: "Mail Topic5",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author6",
    topic: "Mail Topic6",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author7",
    topic: "Mail Topic7",
    unreaded: true,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author8",
    topic: "Mail Topic8",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author9",
    topic: "Mail Topic9",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author10",
    topic: "Mail Topic10",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author11",
    topic: "Mail Topic11",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
  {
    sender: "Mail Author12",
    topic: "Mail Topic12",
    unreaded: false,
    date: "19.11.2022",
    content:
      "Laboris Lorem commodo velit id ex exercitation ad pariatur minim mollit enim sit est. Qui et commodo do voluptate veniam. Fugiat sint id irure aliquip voluptate elit excepteur id deserunt veniam eiusmod. Aute nulla ipsum proident officia ex non. Sit excepteur aliqua ut dolor voluptate cillum deserunt do. Sunt reprehenderit in sunt culpa consectetur consequat est laboris enim incididunt enim reprehenderit.",
  },
]);

const checkboxes = ref(mails.value.map(() => false));
const checkAllCheckbox = ref(false);
const mailReaderKey = ref(false);

const toggleCheckAll = () => {
  let checkboxesMap = checkboxes.value;

  if (checkAllCheckbox.value === true) {
    checkboxesMap = checkboxesMap.map(() => false);
  } else {
    checkboxesMap = checkboxesMap.map(() => true);
  }

  checkboxes.value = checkboxesMap;
};

const openMailReader = (key) => {
  if (key < 0) {
    key = 0;
  }

  if (key === mails.value.length) {
    key = mails.value.length - 1;
  }

  mails.value[key].unreaded = false;
  mailReaderKey.value = key;
};

const deleteSelected = () => {
  const withoutDeleted = [];

  checkboxes.value.forEach((value, key) => {
    if (!value) {
      withoutDeleted.push(mails.value[key]);
    }
  });

  mails.value = withoutDeleted;
  checkboxes.value = mails.value.map(() => false);
};
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.checkbox-col {
  max-width: 40px;
}

.date-col {
  max-width: 120px;
}

.mail-header {
  background: white;
  padding: 10px 5px;
  margin: 0;
}

.mail-row {
  margin: 0;
  background: $lightGray;
  padding: 5px;
  border-bottom: 1px solid darken($lightGray, 10%);
  transition: 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: darken($lightGray, 10%);
  }
}

.mail-reader {
  background: white;
  padding: 25px;
  min-height: 250px;
}

.mail-reader-content {
  padding-top: 15px;
  border-top: 1px solid $borderLighGray;
}

.topic-col,
.sender-col {
  @media screen and (max-width: $mdBreakpoint) {
    margin-left: 40px;
  }
}

.buttons-container {
  max-width: 225px;
  position: absolute;
  top: 38px;
  right: 25px;

  @media screen and (max-width: $lgBreakpoint) {
    max-width: 120px;
  }

  button {
    @media screen and (max-width: $lgBreakpoint) {
      padding: 0 10px;
    }
  }
}

.sender-row,
.topic-row {
  padding-right: 250px;

  @media screen and (max-width: $lgBreakpoint) {
    padding-right: 150px;
  }
}
</style>
