<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Pricat"
    :breadcrumbs-parent-routes="['productInfo']"
    description="Laden Sie hier CSV- und TEXT- Datensätze für Ihre Warenwirtschafts- und EDV-Systeme & Co herunter."
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "./FileItem.vue";

const files = computed(() => [
  {
    file: "pricat/Bridgestone_Deutschland_01.11.2022(B4).csv",
    name: "Pricat - 2022 (CSV)",
    date: "01.10.2022",
    type: "csv",
  },
  {
    file: "pricat/Bridgestone_Deutschland_01.11.2022(B4).txt",
    name: "Pricat - 2022 (TXT)",
    date: "01.10.2022",
    type: "alt",
  },
  {
    file: "pricat/Bridgestone_Deutschland_01.10.2022(B4).csv",
    name: "Pricat - 2022 (CSV)",
    date: "01.09.2022",
    type: "csv",
  },
  {
    file: "pricat/Bridgestone_Deutschland_01.10.2022(B4).txt",
    name: "Pricat - 2022 (TXT)",
    date: "01.09.2022",
    type: "alt",
  },
  {
    file: "pricat/Bandag_B2_2021-10-01_V2.csv",
    name: "Pricat Bandag B2 (CSV)",
    date: "01.10.2021",
    type: "csv",
  },
  {
    file: "pricat/Protread_B4_2021-10-01.csv",
    name: "Pricat Protread B4 (CSV)",
    date: "01.10.2021",
    type: "csv",
  },
  {
    file: "pricat/Protread_B2_2021-10-01.csv",
    name: "Pricat Protread B2 (CSV)",
    date: "01.10.2021",
    type: "csv",
  },
]);
</script>

<style scoped lang="scss"></style>
