import TokenService from './storage.service'
import router from '../router'
import axios from 'axios'
import config from './../config'

const LANG = config.LANG

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    // axios.defaults.withCredentials = true
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        if (error.response.status === 401) {
          router.push('/error')
        }
        return Promise.reject(error)
      },
    )

    axios.interceptors.request.use((config) => {
      config.headers.common.Authorization = `Bearer ${TokenService.getToken()}`

      return config
    })
  },

  setHeader() {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.withCredentials = true
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource) {
    return axios.get('/api/' + LANG + '/' +  resource)
  },

  csrfCookie() {
    return axios.get('/sanctum/csrf-cookie')
  },

  post(resource, data, headers) {
    return axios.post('/api/' + LANG + '/' + resource, data, headers)
  },

  put(resource, data) {
    return axios.put('/api/' + LANG + '/' + resource, data)
  },

  delete(resource) {
    return axios.delete('/api/' + LANG + '/' + resource)
  },

  customRequest(data) {
    return axios(data)
  },
}

export default ApiService
