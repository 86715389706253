<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Preislisten Baumaschinen-/OTR-Reifen"
    :breadcrumbs-parent-routes="['productInfo', 'preislisten']"
    description="
        Laden Sie hier aktuelle Preislisten zu unseren Bridgestone und Firestone Reifen herunte:
        <ul>
          <li>Baumaschinen/OTR</li>
        </ul>
    "
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "../FileItem.vue";

const files = computed(() => [
  {
    file: "otr/BS_Preisliste_OTR_3-2022_D_A.xlsx",
    name: "OTR Preisliste 03/2022",
    description: "(XLS)",
    date: "01.06.2022",
    type: "excel",
  },
  {
    file: "otr/OTR_Preisliste_D_AT_3_2022.pdf",
    name: "OTR Preisliste 03/2022",
    description: "(PDF)",
    date: "01.06.2022",
    type: "pdf",
  },
]);
</script>

<style scoped lang="scss"></style>
