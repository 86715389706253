<template>
  <swiper
    class="text-slider"
    :slides-per-view="1"
    :space-between="0"
    :modules="[Navigation, Pagination]"
    loop
    :autoplay="{
      delay: 200,
      disableOnInteraction: false,
    }"
    :navigation="navigation"
    :pagination="pagination"
  >
    <swiper-slide
      v-for="(slide, key) in props.slides"
      :key="key"
      class="swiper-item"
      :style="slide.background ? `background: url(${background(slide.background)})` : ''"
    >
      <div class="swiper-content">
        <img
          v-if="slide.image"
          :src="background(slide.image)"
          class="mb-3"
          alt="slider image"
        />
        <h3 v-if="slide.title" class="swiper-title">{{ slide.title }}</h3>
        <p class="swiper-description" v-html="slide.description"></p>
        <p
          class="swiper-adnotation mt-3 mb-0 font-10"
          v-if="slide.adnotation"
          v-html="slide.adnotation"
        ></p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { defineProps } from "vue";
import "./slider.scss";

let pagination = false;
let navigation = false;
const background = (name) => require("../../../assets/img/" + name);
const props = defineProps({
  slides: Object,
});

if (props.slides.length > 1) {
  navigation = true;
  pagination = {
    clickable: true,
    type: "custom",
    renderCustom: (swiper, current, total) =>
      `<div class='swiper-custom-pagination'>${current} von ${total}</div>`,
  };
}
</script>

<style scoped lang="scss">
.swiper,
.swiper-wrapper {
  height: 100%;
}
.swiper-item {
  height: 100%;
}

.swiper-content {
  color: white;
}

.swiper-title {
  font-size: 35px;
}

.swiper-description {
  font-size: 18px;
  margin: 0;
}
</style>
