export default {
    pageName: {
        preislisten: 'Preislisten',
        dashboard: 'Home',
        trainings: 'Trainings',
        onlineTrainings: 'Online-Trainings',
        bridgestoneSchulungen: 'Bridgestone Schulungen',
        shop: 'Bridgestone Prämienshop',
        shopProducts: 'Bridgestone Prämienshop',
        shopCategory: "Bridgestone Prämienshop",
        product: 'Produkt',
        productInfo: 'Produkte, Preislisten und mehr',
        pkw: 'Pkw',
        motorrad: 'Motorrad',
        lkwUndBusse: 'Lkw und Busse',
        produktbroschuren: 'Produktbroschüren',
        pricat: 'Pricat',
        landwirtschaft: 'Landwirtschaft',
        baumaschinenOtr: 'Baumaschinen - Otr',
        bDrive: 'B-Drive',
        beanstandungsFormular: 'Beanstandungs-Formular',
        bridgestoneProduktbroschuren: 'Bridgestone Produkte',
        firestoneProduktbroschuren: 'Firestone Produkte',
        technologien: 'Technologien, Markierungen und mehr',
        training: 'Training',
        trainingForm: 'Training form',
        account: 'Kontoauszug',
        pointsOverview: 'Mein Punktekonto',
        stammdaten: 'Meine Stammdaten',
        mailbox: 'Postfach'
    },
    shop: {
        noProducts: 'No products in this category'
    }
}