<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Preislisten Lkw- und Bus-Reifen"
    :breadcrumbs-parent-routes="['productInfo', 'preislisten']"
    description="
        Laden Sie hier aktuelle Preislisten zu unseren Bridgestone und Firestone Reifen herunter:
        <ul>
          <li>Lkw und Busse</li>
        </ul>
    "
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "../FileItem.vue";

const files = computed(() => [
  {
    file: "lkw/Servicepreisliste fuer PKW LLKW LKW 2-2022 (PDF).pdf",
    name: "Servicepreisliste für PKW, LLKW & LKW",
    description: "2/2022 (PDF)",
    date: "2022",
    cover: "RZ_Handel_D_2_2022_final - Thumbnail.png",
    type: "pdf",
  },
  {
    file: "lkw/Pannenservice fuer Nutzfahrzeuge_D_EUR_August2022.pdf",
    name: "Pannenservice für Nutzfahrzeuge <br/> 2022 EUR",
    description: "(PDF)",
    date: "15.09.2022",
    cover: "Pannernservice DACH-DE-CHD.png",
    type: "pdf",
  },
  {
    file: "lkw/Dayton LKW 3_2022.xlsx",
    name: "Dayton LKW Preisliste",
    description: "03/2022",
    date: "01.06.2022",
    type: "excel",
  },
  {
    file: "lkw/Firestone LKW 3_2022.xlsx",
    name: "Firestone LKW Preisliste ",
    description: "03/2022",
    date: "01.06.2022",
    type: "excel",
  },
  {
    file: "lkw/Bridgestone LKW 3_2022.xlsx",
    name: "Bridgestone LKW Preisliste 3/2022",
    date: "01.06.2022",
    type: "excel",
  },
  {
    file: "lkw/Bridgestone LKW_Preisliste_3_2022_DE_AU.pdf",
    name: "Bridgestone LKW Preisliste 03/2022",
    date: "01.06.2022",
    type: "pdf",
  },
  {
    file: "lkw/RZ_Handel_D_1_2022_final_klein.pdf",
    name: "Servicepreisliste Handel",
    description: "(PDF)",
    date: "01.02.2022",
    type: "pdf",
  },
]);
</script>

<style scoped lang="scss"></style>
