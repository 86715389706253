<template>
  <MDBRow class="align-items-center">
    <MDBCol col="" class="zuruck">
      <router-link :to="getBackLink()" class="font-12 fw-bold text-dark">
        <MDBIcon class="me-1 font-10" icon="chevron-left" size="1x" /> ZURÜCK
      </router-link>
    </MDBCol>
    <MDBCol col="">
      <p class="font-10 m-0 ps-lg-2 pt-2 pt-md-0 text-uppercase text-dark">
        <router-link :to="{ name: 'dashboard' }" class="text-dark">Home</router-link>
        <span class="d-inline-block mx-1">/</span>

        <span v-for="route in parentRoutes" :key="route">
          <router-link :to="getLink(route)" class="text-dark">{{
            typeof route === "string" ? $t("pageName." + route) : route.title
          }}</router-link>
          <span class="d-inline-block mx-1">/</span>
        </span>
        {{ props.routeName ? props.routeName : $t("pageName." + currentRoute) }}
      </p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed, defineProps } from "vue";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";

const currentRoute = computed(() => {
  return useRoute().name;
});

const props = defineProps({
  routeName: String,
  parentRoutes: Array,
});

const getLink = (route) => {
  let link = { name: route };

  if (typeof route !== "string") {
    link = route;
  }

  return link;
};

const getBackLink = () => {
  let link = { name: "dashboard" };

  if (props.parentRoutes) {
    link =
      typeof props.parentRoutes[props.parentRoutes.length - 1] === "string"
        ? { name: props.parentRoutes[props.parentRoutes.length - 1] }
        : props.parentRoutes[props.parentRoutes.length - 1];
  }

  return link;
};
</script>

<style scoped lang="scss">
@import "../../../styles/variables";
.zuruck {
  max-width: 100px;

  @media screen and (max-width: $mdBreakpoint) {
    max-width: auto;
    min-width: 100%;
  }
}
</style>
