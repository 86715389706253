import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createI18n } from 'vue-i18n'
import API from './config'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ApiService from './services/api.service'
import messages from './i18n/index'
ApiService.init(API.URL)

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de', 
  messages, 
})

createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(i18n)
  .mount('#app')
