export default {
  10: [
    {
      type: "text",
      description: `<b>TESTEN SIE JETZT IHR WISSEN</b>, UM IHR TRAININGSZERTIFIKAT ZU ERHALTEN! <br />

        Beantworten Sie die folgenden Fragen zum Potenza Sport richtig, um auf der nachfolgenden Seite Ihr Trainingszertifikat zu erhalten.`,
    },
    {
      id: "potenza1",
      label:
        "Welche EU Reifenlabel Klasse für Nasshaftung hat der Potenza Sport?",
      type: "radio",
      answers: [
        {
          label: "Bewertung „B“ für Nasshaftung in allen Dimensionen",
          correct: false,
        },
        {
          label: "Bewertung „AB“ für Nasshaftung in allen Dimensionen",
          correct: false,
        },
        {
          label: "Bewertung „A“ für Nasshaftung in allen Dimensionen",
          correct: true,
        },
      ],
    },
    {
      id: "potenza2",
      label: "Die sportliche Laufflächenkontur des Potenza Sport bietet…?",
      type: "radio",
      answers: [
        {
          label:
            "Eine große Aufstandsfläche für maximale Performance auf trockener Fahrbahn",
          correct: true,
        },
        {
          label:
            "Eine kleinere Aufstandsfläche für maximale Performance auf trockener Fahrbahn",
          correct: false,
        },
        {
          label:
            "Eine ungleichförmige Aufstandsfläche für maximale Performance auf Schnee",
          correct: false,
        },
      ],
    },
    {
      id: "potenza3",
      label:
        "Beim Potenza Sport wird die NanoPro-tech™-Gummimischung verwendet. Dadurch wird… ?",
      type: "radio",
      answers: [
        {
          label: "Der Silika-Anteil reduziert und die Nasshaftung verbessert",
          correct: false,
        },
        {
          label: "Der Silika-Anteil erhöht und die Nasshaftung verbessert",
          correct: true,
        },
        {
          label: "Ein neuer Farbton beim Reifen kreiert",
          correct: false,
        },
      ],
    },
    {
      id: "potenza4",
      label:
        "Wodurch erreicht der Potenza Sport eine maximale Stabilität bei hohen Geschwindigkeiten sowie eine präzise Lenkrückmeldung?",
      type: "radio",
      answers: [
        {
          label: "Durch die Karkasskonstruktion eines Lkw-Reifens",
          correct: false,
        },
        {
          label:
            "Durch die optimierte Karkasskonstruktion mit maximal stabilisiertem Hybrid-Cord",
          correct: true,
        },
        {
          label: "Durch die optimierte Karkasskonstruktion ohne Hybrid-Cord",
          correct: false,
        },
      ],
    },
    {
      id: "potenza5",
      label:
        "Was wird durch die beim Potenza Sport verwendeten 3D-Lamellen erreicht?",
      type: "radio",
      answers: [
        {
          label: "Eine geringere Blocksteifigkeit",
          correct: false,
        },
        {
          label: "Keine Blocksteifigkeit",
          correct: false,
        },
        {
          label: "Eine höhere Blocksteifigkeit",
          correct: true,
        },
      ],
    },
    {
      id: "potenza6",
      label:
        "In welchen Dimensionen ist der Potenza Sport für den Ersatzmarkt erhältlich?",
      type: "radio",
      answers: [
        {
          label: "In 96 Ausführungen von 17 bis 22 Zoll",
          correct: true,
        },
        {
          label: "In 96 Ausführungen von 15 bis 17 Zoll",
          correct: false,
        },
        {
          label: "In 96 Ausführungen von 13 bis 20 Zoll",
          correct: false,
        },
      ],
    },
  ],
};
