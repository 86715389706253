<template>
  <CategoryTop
    banner-image="trainings-overview.jpg"
    title="Mein persönlicher Kontoauszug"
    :breadcrumbs-parent-routes="['account']"
    description="Hier können Sie Ihren persönlichen Bridgestone Plus Kontoauszug zu Ihren erhaltenen und verwendeten Prämienpunkten einsehen. Bitte beachten Sie, dass Punktegutschriften für Ihre Reifenorder je nach Bezugsquelle erst nach bis zu 6 Wochen nach Orderzeitpunkt gutgeschrieben werden."
  >
    <template #right>
      <h3 class="my-4">Punktestand</h3>
      <MDBTable class="table-striped points-table">
        <tbody>
          <tr>
            <td>Reguläre Punkte</td>
            <td>
              <strong>{{ points?.sumStandardPoints }}</strong>
            </td>
          </tr>
          <tr>
            <td>Booster Punkte</td>
            <td>
              <strong>{{ points?.sumBoosterPoints }}</strong>
            </td>
          </tr>
        </tbody>
      </MDBTable>
    </template>
  </CategoryTop>

  <MDBRow>
    <MDBCol>
      <div class="table-responsive">
        <MDBTable class="points-overview table-striped table-bordered">
          <thead>
            <tr>
              <!-- <th scope="col" v-for="(column, key) in columnsStandard" :key="key">
                {{ column }}
              </th>
              <th scope="col" v-for="(column, key) in columnsBoosters" :key="key">
                {{ column }}
              </th>
              <th scope="col" v-for="(column, key) in columnsAdditional" :key="key">
                {{ column }}
              </th> -->
              <th>Datum</th>
              <th>Bezugsquelle / Grund</th>
              <th>Artikel</th>
              <th>Marke</th>
              <th>Produkt</th>
              <th>Typ</th>
              <th>Zoll</th>
              <th>Saison</th>
              <th>Menge</th>
              <th>Standard Punkte</th>
              <th>Booster Produkt</th>
              <th>Booster Saison</th>
              <th>Sonstige Handlung bzw. Ursache</th>
              <th>Punkte Gesamt</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, key) in bank" :key="key">
              <td>{{ account.date }}</td>
              <td>{{ account.source }}</td>
              <td>{{ account.article }}</td>
              <td>{{ account.brand }}</td>
              <td>{{ account.product }}</td>
              <td>{{ account.type }}</td>
              <td>{{ account.dimension }}</td>
              <td>{{ account.liSi }}</td>
              <td>{{ account.saison }}</td>
              <td>{{ account.rim }}</td>
              <td>{{ account.qty }}</td>
              <td>
                <strong>{{ account.points }}</strong>
              </td>
              <td v-for="(boost, key2) in account.boosters" :key="key2">{{ boost }}</td>
              <td>{{ account.provenance }}</td>
              <td>{{ account.sumPoints - account.points }}</td>
            </tr>

            <tr>
              <td>16.03.23</td>
              <td>Prämienshop</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Shop Einkauf Apple AirPods</td>
              <td>-1.074</td>
            </tr>
            <tr>
              <td>23.02.23</td>
              <td>Interpneu</td>
              <td>IPC Nr.</td>
              <td>BS</td>
              <td>Weather C</td>
              <td>A005E</td>
              <td>17</td>
              <td>A/S</td>
              <td>48</td>
              <td>144</td>
              <td>288</td>
              <td></td>
              <td>-</td>
              <td>432</td>
            </tr>
            <tr>
              <td>23.02.23</td>
              <td>Interpneu</td>
              <td>IPC Nr.</td>
              <td>BS</td>
              <td>Turanza</td>
              <td>T005</td>
              <td>18</td>
              <td>SO</td>
              <td>200</td>
              <td>600</td>
              <td>-</td>
              <td>1200</td>
              <td>-</td>
              <td>1800</td>
            </tr>
            <tr>
              <td>17.02.23</td>
              <td>Bridgestone Plus</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Transporter A/S Online-Training</td>
              <td>40</td>
            </tr>
            <tr>
              <td>16.02.23</td>
              <td>Bridgestone Plus</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Blizzak LM005 Online-Training</td>
              <td>40</td>
            </tr>
            <tr>
              <td>15.02.23</td>
              <td>Bridgestone DE</td>
              <td>IPC Nr.</td>
              <td>BS</td>
              <td>Blizzak</td>
              <td>LM005</td>
              <td>18</td>
              <td>WI</td>
              <td>4</td>
              <td>12</td>
              <td>0</td>
              <td>0</td>
              <td>-</td>
              <td>12</td>
            </tr>
            <tr>
              <td>02.02.23</td>
              <td>Bridgestone Plus</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Punkte Profilvervollständigung</td>
              <td>200</td>
            </tr>
            <tr>
              <td>02.02.23</td>
              <td>Bridgestone Plus</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Kontoeröffnung</td>
              <td>0</td>
            </tr>
          </tbody>
        </MDBTable>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBTable } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
store.dispatch("bankStatement");

const columnsStandard = computed(() => store.getters.getBankStatementHeadersStandard);
const columnsBoosters = computed(() => store.getters.getBankStatementHeadersBoosters);
const columnsAdditional = computed(() => store.getters.getBankStatementHeadersAdditional);
const points = computed(() => store.getters.getPoints);
const bank = computed(() => store.getters.getBankStatementData);

columnsAdditional;
columnsBoosters;
columnsStandard;
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.points-table {
  border: 1px solid $borderLighGray;
}

.points-overview {
  width: 100%;

  td,
  th {
    padding: 5px;
    text-align: center;
  }

  th {
    background: $darkGray;
    color: white;
  }
}
</style>
