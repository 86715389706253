<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    :modules="[Navigation, Pagination, Autoplay]"
    loop
    :autoplay="{
      delay: 8000,
      disableOnInteraction: false,
    }"
    navigation
    :pagination="{
      clickable: true,
      type: 'custom',
      renderCustom: (swiper, current, total) =>
        `<div class='swiper-custom-pagination'>${current} von ${total}</div>`,
    }"
  >
    <swiper-slide
      v-for="(slide, key) in props.slides"
      :key="key"
      class="swiper-item"
      :style="`background-image: url(${background(slide.background)})`"
    >
      <div class="swiper-content">
        <h3 :class="`swiper-title ${slide.color ? `text-${slide.color}` : ''}`">
          {{ slide.title }}
        </h3>
        <p
          :class="`swiper-description ${slide.color ? `text-${slide.color}` : ''}`"
          v-html="slide.description"
        ></p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { defineProps } from "vue";
import "../General/TextSlider/slider.scss";

const props = defineProps({
  slides: Object,
});

const background = (name) => require("../../assets/img/" + name);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.swiper,
.swiper-wrapper {
  height: 100%;
}
.swiper-item {
  height: 100%;
}

.swiper-content {
  color: white;
  padding: 32px 125px 32px 32px;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;

  @media screen and (max-width: $lgBreakpoint) {
    padding: 35px 25px;
  }
}

.swiper-title {
  font-size: 35px;
  text-shadow: 1px 1px 10px rgb(0 0 0 / 50%);

  @media screen and (max-width: $lgBreakpoint) {
    font-size: 25px;
  }
}

.swiper-description {
  font-size: 18px;
  margin: 0;
  text-shadow: 1px 1px 10px rgb(0 0 0 / 50%);

  @media screen and (max-width: $lgBreakpoint) {
    font-size: 16px;

    br {
      display: none;
    }
  }
}
</style>
