<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Firestone Produkte"
    :breadcrumbs-parent-routes="['productInfo', 'produktbroschuren']"
    description="Laden Sie hier aktuelle Broschüren und Flyer zu unseren Firestone Reifen für Pkw, SUV und Transporter herunter."
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import FileItem from "../FileItem.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";

const files = computed(() => [
  {
    file: "broschuren/firestone/FS Vanhawk Multiseason_2021_2pager_Ansicht.pdf",
    name: "VANHAWK MULTISEASON",
    description: "Transporter - All Season",
    date: "Produktübersicht 2021",
    type: "pdf",
  },
  {
    file: "broschuren/firestone/FS Winterhawk 4_2020_2pager_Ansicht.pdf",
    name: "WINTERHAWK 4",
    description: "Pkw, SUV - Winter",
    date: "Produktübersicht 2020",
    type: "pdf",
  },
]);
</script>

<style scoped lang="scss"></style>
