import Auth from "../views/Auth.vue";
import Register from "../components/Register.vue";
import Login from "@/components/Login.vue";

export default [
  {
    path: "/",
    name: "login",
    component: Auth,
    props: {
      content: Login
    }
  },
  {
    path: "/register",
    name: "register",
    component: Auth,
    props: {
      content: Register
    }
  },
];
