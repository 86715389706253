export default [
  {
    name: "Reifentechnik Basiswissen",
    details: `
        <b>Kosten und Teilnahmeinformationen</b>
        <br /> <br />

        <b>Im Bridgestone TRAINING-CENTER</b> <br />
        Dauer: 1 Tag <br />
        Kosten p. P.: 99,- Euro <br />
        Teilnehmerzahl: mind. 5 - max. 15 Personen <br />
        (inkl. Mittagessen und Getränke) <br />
        <br />
        <br />
        <b>TRAINING VOR ORT bei Ihnen</b> <br />
        Dauer: 1 Tag <br />
        Kosten: 500,- Euro (pauschal) <br />
        Teilnehmerzahl: mind. 8 - max. 15 Personen <br />
        `,
    description: `
    <b>Inhalte der Schulung</b>

    <ul>
        <li>Technische Merkmale von Performance-Reifen</li>
        <li>Eigenschaften der jeweiligen Reifenausführungen und -kombinationen</li>
        <li>Zielgruppen & Fahrzeugsegmente: Welcher Reifen passt zu welchem Fahrzeug?</li>
        <li>Produkteigenschaften: Vorteile für den Endkunden</li>
        <li>Reifentechnik: Konstruktion, Reifentypen, Reifenbeschriftung & gesetzliche Bestimmungen und Vorschriften</li>
        <li>Darstellung der verschiedenen Arten der Umbereifung</li>
    </ul>
    `,
    image: "schulungen/reifentechnik_basiswissen_784x514.jpg",
  },
  {
    name: "Reifentechnik für Fortgeschrittene",
    details: `
    <b>Kosten und Teilnahmeinformationen</b> <br />
    <br /> <br />

    <b>Im Bridgestone TRAINING-CENTER</b> <br />
    Dauer: 1 Tag <br />
    Kosten p. P.: 149,- Euro <br />
    Teilnehmerzahl: mind. 5 - max. 15 Personen <br />
    (inkl. Mittagessen und Getränke) <br />
    <br /> <br />
    
    <b>TRAINING VOR ORT bei Ihnen</b> <br />
    Dauer: 1 Tag <br />
    Kosten: 700,- Euro (pauschal) <br />
    Teilnehmerzahl: mind. 8 - max. 15 Personen <br />
    
        `,
    description: `
        <b>Inhalte der Schulung</b> <br /> <br />

        <ul>
            <li>Reifentechnik: Konstruktion, Reifentypen, Reifenbeschriftung &</li>
            <li>gesetzliche Bestimmungen und Vorschriften</li>
            <li>Die richtige Reifenauswahl: Welcher Reifen passt zu welchem Fahrzeug?</li>
            <li>Darstellung der verschiedenen Arten der Umbereifung</li>
            <li>(auf Basis von Geschwindigkeit oder Achslasten und Abmessungen)</li>
            <li>Reifendruck: Luftdruckberechnung & Herstellerbescheinigungen</li>
            <li>Reifenschäden: Schäden erkennen und richtig beurteilen</li>
        </ul>
    `,
    image: "schulungen/reifentechnik_fuer_fortgeschrittene_784x514.jpg",
  },
  {
    name: "Produkttraining",
    details: `
        <b>Kosten und Teilnahmeinformationen</b>
        <br /> <br />

        <b>Im Bridgestone TRAINING-CENTER</b> <br />
        Dauer: 1 Tag <br />
        Kosten p. P.: 49,- Euro <br />
        Teilnehmerzahl: mind. 5 - max. 15 Personen <br />
        (inkl. Mittagessen und Getränke)
        <br /> <br />
        
        <b>TRAINING VOR ORT bei Ihnen</b> <br />
        Dauer: 1 Tag <br />
        Kosten: 250,- Euro (pauschal) <br />
        Teilnehmerzahl: mind. 8 - max. 15 Personen <br />
        `,
    description: `
        <b>Inhalte der Schulung</b>
        <br /> <br />
        <ul>
            <li>Produktvorstellung der Bridgestone und Firestone Reifen (Sommerreifen, Winterreifen, Ganzjahresreifen)</li>
            <li>Erarbeitung der technischen Eigenschaften</li>
            <li>Den Endkunden überzeugen: Erklärung und Argumente der produktspezifischen Alleinstellungsmerkmale</li>
        </ul>
    `,
    image: "schulungen/produkttraining_784x514.jpg",
  },
  {
    name: "Technologietraining",
    details: `
        <b>Kosten und Teilnahmeinformationen</b>
        <br /> <br />
        <b>Im Bridgestone TRAINING-CENTER</b> <br />
        Dauer: 1 Tag <br />
        Kosten p. P.: 49,- Euro <br />
        Teilnehmerzahl: mind. 5 - max. 15 Personen <br />
        (inkl. Mittagessen und Getränke)
        <br /> <br />
        
        <b>TRAINING VOR ORT bei Ihnen</b> <br />
        Dauer: 1 Tag <br />
        Kosten: 250,- Euro (pauschal) <br />
        Teilnehmerzahl: mind. 8 - max. 15 Personen
        `,
    description: `
    <b>Inhalte der Schulung</b>
    <br /> <br />
    <ul>
        <li>Erklärung der unterschiedlichen Technologien: DriveGuard, B-Seal, B-Silent, Enliten & Ologic</li>
        <li>Erarbeitung der technischen Eigenschaften</li>
        <li>Den Endkunden überzeugen: Erklärung und Argumente der produktspezifischen Alleinstellungsmerkmale</li>
        <li>Richtiger Einsatz: Zielfahrzeuge & Einsatzempfehlung</li>
    </ul>
    `,
    image: "schulungen/technologie_training_784x514.jpg",
  },
  {
    name: "Einstieg in Reifentechnik und Montage",
    details: `
    <b>Kosten und Teilnahmeinformationen</b>
    <br /> <br />

    <b>Im Bridgestone TRAINING-CENTER</b> <br />
    Dauer: 1 Tag <br />
    Kosten p. P.: 190,- Euro <br />
    Teilnehmerzahl: mind. 4 - max. 8 Personen <br />
    (inkl. Mittagessen und Getränke)
    <br /> <br />
    
    <b>TRAINING VOR ORT bei Ihnen</b> <br />
    Dauer: 1 Tag <br />
    Kosten: 3.500,- Euro (pauschal) <br />
    Teilnehmerzahl: mind. 10 - max. 12 Personen <br />
    
        `,
    description: `
    <b>Inhalte der Schulung</b> <br /> <br />

    FÜR VERKAUFSPERSONAL!
    <br /> <br />
    <ul>
        <li>Ausbau der fachspezifischen Kenntnisse</li>
        <li>Optimierung Ihrer Verkaufsgespräche</li>
        <li>Individuelle Bearbeitung der technischen Herausforderungen von Kunden</li>
        <li>Vermittlung von Basiswissen und Grundlagen des Montageablaufs</li>
        <li>Grundkenntnisse für die Durchführung von Montagen</li>
    </ul>
    `,
    image: "schulungen/einstieg_in_reifentechnik_und_montage_784x514.jpg",
  },
  {
    name: "Montageschulung mit WdK Zertifizierung",
    details: `
    <b>Kosten und Teilnahmeinformationen</b> <br /> <br />


    <b>Im Bridgestone TRAINING-CENTER</b> <br />
    Dauer: 2 Tage <br />
    Kosten p. P.: 485,- Euro <br />
    Teilnehmerzahl: max. 4 Personen <br />
    (inkl. Mittagessen und Getränke)
    <br /> <br />
    
    <b>TRAINING VOR ORT bei Ihnen</b> <br />
    Dauer: 2 Tage <br />
    Kosten: 3.500,- Euro (pauschal) <br />
    Teilnehmerzahl: max. 4 Personen <br />
    
        `,
    description: `
    <b>Inhalte der Schulung</b>
    <br /> <br />
    <ul>
        <li>UHP- und RFT-Reifen: Technik und Funktion</li>
        <li>UHP- und RFT-Reifen: Gesetzliche Grundlagen, Empfehlungen und Vorschriften</li>
        <li>Luftdrucküberwachungssysteme: Typen und Funktionen</li>
        <li>UHP- und RFT-Reifen: Montage und Demontage, inkl. Hinweisen zu typischen und sicherheitsrelevanten Merkmalen sowie Schadensbildern</li>
        <li>Prüfung in Theorie und Praxis. Bei Bestehen erfolgt die WDK Zertifizierung</li>
    </ul>
    `,
    image: "schulungen/montageschulung_mit_wdk_Zertifizierung_784x514.png",
  },
  {
    name: "Montageschulung ohne WdK Zertifizierung",
    details: `
        <b>Kosten und Teilnahmeinformationen</b>
        <br /> <br />

        <b>Im Bridgestone TRAINING-CENTER</b> <br />
        Dauer: 1 Tag <br />
        Kosten p. P.: 385,- Euro <br />
        Teilnehmerzahl: mind. 5 - max. 10 Personen <br />
        (inkl. Mittagessen und Getränke)
        <br /> <br />
        
        <b>TRAINING VOR ORT bei Ihnen</b> <br />
        Dauer: 2 Tage <br />
        Kosten: 3.500,- Euro (pauschal) <br />
        Teilnehmerzahl: mind. 5 - max. 12 Personen <br />
    
        `,
    description: `
        <b>Inhalte der Schulung</b>
        <br /> <br />
        <ul>
            <li>UHP- und RFT-Reifen: Technik und Funktion</li>
            <li>UHP- und RFT-Reifen: Gesetzliche Grundlagen, Empfehlungen und Vorschriften</li>
            <li>Luftdrucküberwachungssysteme: Typen und Funktionen</li>
            <li>UHP- und RFT-Reifen: Montage und Demontage, inkl. Hinweisen zu typischen und sicherheitsrelevanten Merkmalen</li>
            <li>Durchführung Theorie und Praxis nach WDK Richtlinien</li>
        </ul>
    `,
    image: "schulungen/montageschulung_784x514.jpg",
  },
  {
    name: "Reifenreparatur Kaltverfahren",
    details: `
    <b>Kosten und Teilnahmeinformationen</b>
    <br /> <br />

    <b>Im Bridgestone TRAINING-CENTER</b> <br />
    Dauer: 1 Tag <br />
    Kostenpauschale.: 1.000- Euro <br />
    Teilnehmerzahl: mind. 4 - max. 8 Personen <br />
    (inkl. Mittagessen und Getränke) <br />
    <br /> <br />
    
    <b>TRAINING VOR ORT bei Ihnen</b>
    <br /> <br />
    Nicht möglich 
        `,
    description: `
    <b>Inhalte der Schulung</b> <br /> <br />
    <ul>
        <li>Reifenreparatur von PKW Reifen in Theorie und Praxis</li>
        <li>Theorie: Gesetzliche Grundlagen & Vorschriften der Unfallverhütung</li>
        <li>Übersicht über Reifentypen und -kennzeichnung</li>
        <li>Funktionsprinzip der Vulkanisation</li>
        <li>Praxis: Anwendungsübungen zum Verfahren</li>
        <li>Die richtige Reifenauswahl - welcher Reifen passt zu welchem Kunden?</li>
        <li>Reparaturfehler und -grenzen erkennen</li>
        <li>Analyse von Standard- und Grenzfällen der Reparatur an der Lauffläche</li>
    </ul>
    `,
    image: "schulungen/reifenreparatur_kaltverfahren_784x514.jpg",
  },
  {
    name: "Reifenreparatur Heißverfahren",
    details: `
    <b>Kosten und Teilnahmeinformationen</b>
    <br /> <br />

    <b>Im Bridgestone TRAINING-CENTER</b> <br />
    Dauer: 2 Tage <br />
    Kostenpauschale.: 1.750- Euro <br />
    Teilnehmerzahl: mind. 4 - max. 8 Personen <br />
    (inkl. Mittagessen und Getränke) <br /> <br />
    
    
    <b>TRAINING VOR ORT bei Ihnen</b> <br /> <br />
    
    Nicht möglich 
    
        `,
    description: `
    <b>Inhalte der Schulung</b>
    <br /> <br />
    <ul>
        <li>Reifenreparatur von PKW Reifen in Theorie und Praxis</li>
        <li>Theorie: Gesetzliche Grundlagen, Vorschriften der Unfallverhütung & Heizzeitenkunde</li>
        <li>Übersicht über Reifentypen und -kennzeichnung</li>
        <li>Funktionsprinzip der Vulkanisation</li>
        <li>Praxis: Anwendungsübungen zu beiden Verfahren</li>
        <li>Einführung in das Heißvulkanisationsverfahren</li>
        <li>Die richtige Reifenauswahl - welcher Reifen passt zu welchem Kunden?</li>
        <li>Reparaturfehler und -grenzen erkennen</li>
        <li>Analyse von Standard- und Grenzfällen der Reparatur an der Lauffläche, Schulter und Seitenwand</li>
    </ul>
    `,
    image: "schulungen/reifenreparatur_heissverfahren_784x514.jpg",
  },
  {
    name: "Achsvermessung",
    details: `
        <b>Kosten und Teilnahmeinformationen</b>
        <br /> <br />

        <b>Im Bridgestone TRAINING-CENTER</b> <br />
        Dauer: 1 Tag <br />
        Kostenpauschale: 1.000,- Euro <br />
        Teilnehmerzahl: max. 10 Personen <br />
        (inkl. Mittagessen und Getränke)
        <br /> <br />
        
        <b>TRAINING VOR ORT bei Ihnen</b> <br />
        Dauer: 1 Tag <br />
        Kosten: 3.500,- Euro (pauschal) <br />
        Teilnehmerzahl: max. 10 Personen
        `,
    description: `
    <b>Inhalte der Schulung</b>
    <br /> <br />
    <ul>
        <li>Überblick über das aktuelle Produktportfolio</li>
        <li>Erklärung der notwendigen Grundeinstellungen</li>
        <li>Ablauf einer Achsenvermessung</li>
        <li>Unterscheidung & Technischer Aufbau 3D/CCD</li>
        <li>Besondere Merkmale der Datenbank, in der Auswahl der Fahrzeuge und bei dynamischen Solldaten</li>
        <li>Adäquates Arbeiten nach Herstellervorgaben und Besonderheiten des benötigten Zubehörs</li>
        <li>Anpassung am Fahrzeug, Radhalter und fahrzeugspezifisches Zubehör</li>
        <li>Auswahl geeigneter Messplätze</li>
        <li>Inbetriebnahme (Zusammenbau, Anbringen der Halter bei 3D Geräten,</li>
        <li>Aktivieren der Datenbank)</li>
        <li>Ablauf der Einweisung beim Kunden</li>
        <li>Erste Hilfe bei Störungen</li>
    </ul>
    `,
    image: "schulungen/Achsvermessung_784x514.jpg",
  },
  {
    name: "Bridgestone Potenza Sport",
    details: `

    Der neue Bridgestone Ultra-High-Performance-Reifen
     <br /> <br />
    Erfahren Sie in unserem kompakten Trainingsvideo alles Wichtige zum neuen
    Bridgestone Potenza Sport und rüsten Sie sich mit überzeugenden
    Produktargumenten sowie Technik-Expertise für die kommenden Verkaufsgespräche!
        `,
    description: `
        <b>Zertifikat und 40 Extrapunkte für Sie!</b> <br />
        Sichern Sie sich Ihr persönliches Teilnahmezertifikat <br />
        und zusätzlich 40 Extrapunkte für den Prämienclub. <br /><br />
        Und wie? Ganz einfach: Schließen Sie den Test zum <br />
        Training nach dem Video erfolgreich ab! Der Test <br />
        erscheint, sobald Sie das Video vollständig angeschaut <br />
        haben! <br />
        <br />
        Viel Erfolg!
    `,
    vimeo: "https://player.vimeo.com/video/679143302?controls=true&amp;loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false",
  },
];
