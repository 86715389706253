<template>
  <CategoryTop
    banner-image="preislisten/header_produkte_preislisten_1192x282.jpg"
    title="Produkte, Preislisten und mehr"
    description="Willkommen im Informationsbereich rund um die Produkte von Bridgestone und Firestone. <br/> Hier finden Sie aktuelle Broschüren und Preislisten sowie hilfreiche Links und Formulare."
  />

  <MDBRow>
    <MDBCol lg="4" md="6" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 font-16"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

const trainings = computed(() => [
  {
    name: "Produkt-<br/>broschüren",
    link: { name: "produktbroschuren" },
    description:
      "Aktuelle Bridgestone und Firestone<br/> Produktbroschüren zum Downloaden",
  },
  {
    name: "Preislisten",
    link: { name: "preislisten" },
    description: "Aktuelle Bridgestone und Firestone<br/> Preislisten zum Downloaden",
  },
  {
    name: "Pricat",
    link: { name: "pricat" },
    description: "CSV- und TEXT- Datensätze zum Download<br/> für Ihre WaWi-Systeme & Co",
  },
  {
    name: "B-DRIVE",
    link: { name: "bDrive" },
    description: "Aktuelle Logos, Produktabbildungen und<br/> Marketingvorlagen",
  },
  {
    name: "Beanstandungs-<br/>Formular",
    link: { name: "beanstandungsFormular" },
    description: "Eingabeformular für aktuelle<br/> Beanstandungen zu unseren Produkten",
  },
]);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.training {
  background: linear-gradient(
    142deg,
    rgba(205, 205, 205, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  min-height: 200px;
}

.training-title {
  color: white;
}
</style>
