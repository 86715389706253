import ApiService from './api.service'
import TokenService from './storage.service'

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  async me() {
    try {
      return await ApiService.get('auth/me')
    } catch (e) {
      console.log(e)
    }
  },

  async userData(hashId) {
    try {
      return await ApiService.get(`users/${hashId}?with=profile,address`)
    } catch (e) {
      console.log(e)
    }
  },

  async bankStatement() {
    try {
      return await ApiService.get('user/bank_statement')
    } catch (e) {
      console.log(e)
    }
  },

  async categories() {
    try {
      return await ApiService.get('categories')
    } catch (e) {
      console.log(e)
    }
  },

  async categoryProducts(id, page) {
    let pageUrl = '';

    if(page) {
      pageUrl = '?page='+ page
    }

    try {
      return await ApiService.get('categories/' + id + '/products' + pageUrl)
    } catch (e) {
      console.log(e)
    }
  },

  async products(page) {
    let pageUrl = '';

    if(page) {
      pageUrl = '?page='+ page
    }

    try {
      return await ApiService.get('products' + pageUrl)
    } catch (e) {
      console.log(e)
    }
  },

  async product(id) {
    try {
      return await ApiService.get('products/' + id + '/show')
    } catch (e) {
      console.log(e)
    }
  },

  async deleteCartItem(token) {
    try {
      return await ApiService.delete('cart/item/' + token)
    } catch (e) {
      console.log(e)
    }
  },

  async createOrder(data) {
    try {
      return await ApiService.post('orders', data)
    } catch (e) {
      console.log(e)
    }
  },

  async addItem(productData) {
    try {
      if(productData?.token) {
        return await ApiService.put('cart/item/' + productData.token, productData)
      }
      return await ApiService.put('cart/item', productData)

    } catch (e) {
      console.log(e)
    }
  },

  async login(username, password) {
    const data = {
      username: username,
      password: password,
    }

    try {
      await ApiService.csrfCookie()
      const response = await ApiService.post('auth/login', data)

      TokenService.saveToken(response.data.data.token)
      ApiService.setHeader()

      return response.data.data.token
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail,
      )
    }
  },

  async logout() {
    try {
      await ApiService.post('users/logout')
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
    } catch (error) {
      console.log(error)
    }
  },
}

export { UserService, AuthenticationError }
