<template>
  <CategoryTop
    banner-image="schulungen-banner.png"
    :breadcrumbs-parent-routes="['trainings']"
    title="Unsere Online-Trainings"
    description="Sichern sie sich Expertenwissen und bei erfolgreicher Teilnahme an den jeweiligen Tests zum Training Ihr<br> persönliches Teilnahme-Zertifikat sowie einmalig 40 Extrapunkte für den Prämienclub"
  />

  <MDBRow>
    <MDBCol xl="9" class="mb-xl-3">
      <p class="pe-3">
        Jetzt unsere aktuellen Online-Trainings entdecken und sofort hier auf der
        Plattform teilnehmen:
      </p>
    </MDBCol>
    <MDBCol xl="3" class="sort-select-wrap mb-3 mb-xl-0">
      <select v-model="filterBy" class="sort-select font-12">
        <option value="all-season">All Season</option>
        <option value="summer">Sommer</option>
        <option value="winter">Winter</option>
      </select>
      <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
    </MDBCol>

    <MDBCol lg="4" md="6" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        to="/training/online-training"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${getImage(training.image)})` }"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 font-16"></p>
    </MDBCol>
    <MDBCol lg="4" md="6" class="mb-4">
      <p
        class="training bg-white d-flex align-items-center justify-content-center text-center p-2"
      >
        Wir erweitern regelmäßig unser <br />
        Angebot für Sie! <br /><br />
        Über neue Trainings informieren <br />
        wir Sie in Ihrem Postfach.
      </p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";

import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { computed, ref } from "vue";

const filterBy = ref("all-season");
const getImage = (img) => require("../../../assets/img/" + img);
const allTrainings = [
  {
    image: "potenza-sport.png",
    description: "Bridgestone Potenza Sport",
    type: ["all-season", "summer"],
  },
  {
    image: "firestone-sommer.png",
    description: "Firestone Sommer-Training",
    type: ["all-season", "summer"],
  },
  {
    image: "transporter-ganzjahresreifen.png",
    description: "Transporter Ganzjahresreifen",
    type: ["all-season", "winter"],
  },
  {
    image: "verkaufstraining.jpg",
    description: "Verkaufstraining",
    type: ["all-season", "winter"],
  },
];
const trainings = computed(() =>
  allTrainings.filter((training) => training.type.includes(filterBy.value))
);
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.training {
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: 175px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 140px;
  }

  @media screen and (max-width: $xlBreakpoint) {
    min-height: 155px;
  }

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 179px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 298px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 56vw;
  }
}

.training-title {
  color: white;
}

.sort-select {
  border: 2px solid $secondaryGray;
  color: $lightGray;
  appearance: none;
  padding: 3px 40px 3px 10px;
  min-width: 240px;
  outline: none;

  @media screen and (max-width: $xxlBreakpoint) {
    min-width: 100px;
  }
}

.sort-select-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.sort-icon {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
