<template>
  <CategoryTop
    banner-image="trainings-overview.jpg"
    title="Unser Trainingsangebot"
    description="Vorsprung durch Wissen: Sichern Sie sich mit unseren Trainingsangeboten exklusives Fachwissen für Sie und Ihr Team. Ob mit unseren Online-Trainings, an denen Sie direkt ihr auf der Plattform  teilnehmen können, mit unseren Schulungen und Praxisseminaren im Bridgestone Trainingscenter oder auch mit externen Trainings, deren Kosten Sie mit Ihren Prämienpunkten reduzieren können."
  />

  <MDBRow>
    <MDBCol lg="4" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 font-16"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

const trainings = computed(() => [
  {
    name: "Online <br/> Trainings",
    link: "/online-trainings",
    description:
      "Direkt hier auf der Plattform teilnehmen und 40 Extrapunkte für den Prämienclub sichern.",
  },
  {
    name: "Bridgestone <br/>  Schulungen",
    link: "/bridgestone-schulungen",
    description:
      "Entdecken Sie das Schulungsangebot in unserem Trainingscenter oder bei Ihnen vor Ort.",
  },
  {
    name: "Externe <br/>  Trainings",
    link: "/online-trainings",
    description:
      "Setzen Sie Ihre Punkte für Drittanbieter-Trainings ein und reduzieren Sie so Ihre Kosten.",
  },
]);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.training {
  background: linear-gradient(
    142deg,
    rgba(205, 205, 205, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  min-height: 200px;
}

.training-title {
  color: white;
}
</style>
