import { createRouter, createWebHistory } from "vue-router";
import BankStatement from "../views/BankStatement";
import Shop from "./Shop";
import Auth from "./Auth";
import ProductInfo from "./ProductInfo";
import Trainings from "./Trainings";
import Default from '../views/Default.vue';
import Dashboard from '@/components/Dashboard/index';
import PageNotFound from "../views/404.vue";
import User from './User';

const routes = [
  {
    path: "/bank_statement",
    name: "BankStatement",
    component: BankStatement,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Default,
    props: {
      content: Dashboard
    }
  },
  ...Shop,
  ...Auth,
  ...Trainings,
  ...ProductInfo,
  ...User,
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
