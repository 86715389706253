<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Preislisten Landwirtschafts-Reifen"
    :breadcrumbs-parent-routes="['productInfo', 'preislisten']"
    description="
        Laden Sie hier aktuelle Preislisten zu unseren Bridgestone und Firestone Reifen herunter:
        <ul>
          <li>Landwirtschaft</li>
        </ul>
    "
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "../FileItem.vue";

const files = computed(() => [
  {
    file: "landwirtschaft/Gesamt-KB Preisliste 04-2022 (XLS) DL AU.xlsx",
    name: "Gesamt-KB Preisliste",
    description: "04/2022 (XLS)",
    date: "01.09.2022",
    type: "excel",
  },
  {
    file: "landwirtschaft/Gesamt-KB Preisliste 04-2022 (PDF).pdf",
    name: "Gesamt-KB Preisliste",
    description: "04/2022 (PDF)",
    date: "01.09.2022",
    cover: "BS_FS_AS_Preisliste_4_2022_DE_AU-Thumbnail.png",
    type: "pdf",
  },
  {
    file: "landwirtschaft/RZ_AS_DAT_3_2022_ES_low.pdf",
    name: "Gesamt-KB Preisliste",
    description: "03/2022 (PDF)",
    date: "01.06.2022",
    type: "pdf",
  },
  {
    file:
      "landwirtschaft/AG Bridgestone KB-Preisliste 03 2022 - DL AU Vorlage print.xlsx",
    name: "Gesamt-KB Preisliste",
    description: "03/2022 (XLS)",
    date: "01.06.2022",
    type: "excel",
  },
]);
</script>

<style scoped lang="scss"></style>
