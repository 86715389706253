<template>
  <MDBCard class="mb-3 mb-lg-4 welcome">
    <MDBCardBody>
      <MDBCardTitle>Willkommen, <br />Reifenhandel Mustermann</MDBCardTitle>
      <MDBCardText>
        <p>
          Persönliches Profil vervollständigen und zusätzlich
          <span class="success-green fw-bold"> 200 Punkte sichern. </span>
        </p>
        <DashboardLink :to="{ name: 'stammdaten' }"> PROFIL ANZEIGEN </DashboardLink>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>

  <MDBCard class="mailbox">
    <MDBCardBody>
      <MDBCardTitle>Mein Postfach</MDBCardTitle>
      <MDBCardText>
        <p>
          Neue Nachrichten in Ihrem Postfach
          <span class="message-notification">9</span>
        </p>
        <DashboardLink :to="{ name: 'mailbox' }"> NACHRICHTEN ANZEIGEN </DashboardLink>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>

  <MDBCard class="points-overview mb-3 mb-lg-0">
    <MDBCardBody>
      <MDBCardTitle>Punktestand</MDBCardTitle>
      <MDBCardText>
        <p class="mb-0 font-18">Ihr aktueller Punktestand:</p>
        <h2 class="success-green fw-bold">{{ user.points }} Punkte</h2>
        <p class="text-small">Letzte Aktualisierung: TT.MM.JJJJ</p>
        <DashboardLink :to="{ name: 'pointsOverview' }"
          >PUNKTEÜBERSICHT ANZEIGEN</DashboardLink
        >
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";
import DashboardLink from "../Dashboard/Dashboard-link.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
store.dispatch("me");
const user = computed(() => store.getters.getMe);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.degree {
  position: absolute;
  font-size: 18px;
  top: 0;
  left: 0;
  padding: 6px 1.5rem;
  width: 290px;
  background: rgb(85, 85, 85);
  background: linear-gradient(
    255deg,
    rgba(85, 85, 85, 1) 0%,
    rgba(227, 227, 227, 1) 20%,
    rgba(166, 166, 166, 1) 43%,
    rgba(245, 245, 245, 1) 62%,
    rgba(184, 184, 184, 1) 83%,
    rgba(124, 124, 124, 1) 100%
  );
}

.degree:after {
  content: "";
  width: 13px;
  right: -5px;
  top: 1px;
  height: 46px;
  background: white;
  position: absolute;
  transform: rotate(19deg);
}

.with-degree {
  padding-top: 40px;
}

.points-overview {
  min-height: 273px;
}

.message-notification {
  background: #f2790d;
  color: white;
  display: inline-block;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.mailbox {
  margin-bottom: 78px;
  min-height: 194px;

  @media screen and (max-width: $lgBreakpoint) {
    margin-bottom: 1rem;
  }
}

.welcome {
  min-height: 282px;
}
</style>
