<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Preislisten Pkw-, 4x4- und Transporter-Reifen"
    :breadcrumbs-parent-routes="['productInfo', 'preislisten']"
    description="
    Laden Sie hier aktuelle Preislisten zu unseren Bridgestone und Firestone Reifen herunter:
      <ul>
        <li>Pkw, 4x4 und Transporter</li>
      </ul>
  "
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "../FileItem.vue";

const files = computed(() => [
  {
    file: "pkw/PC4 Preisliste alle Saisons_BS_FS_5_2022_EUR.xlsx",
    name: "Bridgestone Firestone",
    description: "05/2022 (XLSX)",
    date: "01.06.2022",
    type: "excel",
  },
  {
    file: "pkw/BS FS_PC4_Preisliste_Winter_5_2022-EUR.pdf",
    name: "Bridgestone Firestone",
    description: "05/2022 (PDF)",
    date: "01.06.2022",
    type: "pdf",
  },
]);
</script>

<style scoped lang="scss"></style>
