<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Preislisten Motorrad-Reifen"
    :breadcrumbs-parent-routes="['productInfo', 'preislisten']"
    description="
        Laden Sie hier aktuelle Preislisten zu unseren Bridgestone Reifen herunter:
        <ul>
          <li>Motorrad</li>
        </ul>
    "
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import FileItem from "../FileItem.vue";

const files = computed(() => [
  {
    file: "motorrad/Lay_preiseinleger_2_2022_DL_AU_02.pdf",
    name: "Motorradreifen",
    description: "Preiseinleger 02/2022 (PDF)",
    date: "01.05.2022",
    type: "pdf",
  },
  {
    file: "motorrad/LAY_BSD_Bevorratungsmappe_22_51.finalpdf.pdf",
    name: "L-Katalog 2022",
    date: "01.04.2022",
    type: "pdf",
  },
  {
    file: "motorrad/PL_2_2022_DL_AU_MAI_2022_final.xlsx",
    name: "Motorradreifen 02/2022",
    description: "(XLSX)",
    date: "01.05.2022",
    type: "excel",
  },
]);
</script>

<style scoped lang="scss"></style>
