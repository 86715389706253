<template>
  <CategoryTop
    banner-image="schulungen-banner.png"
    :breadcrumbs-parent-routes="['trainings']"
    title="Unsere Schulungen im Trainings-Center und vor Ort bei Ihnen"
    description="Mit unseren ganzheitlichen Schulungen bringen Sie Ihre Kompetenz und die Ihres Teams nach vorne. Die <br/> Schulungen finden Wahlweise (je nach Angebot) in unserem <b>Technik- und Kompetenzcenter in Dietzenbach</b><br/> statt <b>oder exklusiv bei Ihnen vor Ort im Betrieb</b>.
<br/><br/>
<b>Der besondere Vorteil für Sie: Setzen Sie Ihre Prämienpunkte ein, um die Kosten zu reduzieren!</b>
"
  />

  <MDBRow>
    <MDBCol md="9" class="mb-0 mb-md-3">
      <p>Jetzt unsere aktuellen Schulungen entdecken und Buchung anfragen:</p>
    </MDBCol>
    <MDBCol md="3" class="sort-select-wrap mb-3 mb-md-0">
      <select v-model="filterBy" class="sort-select font-12">
        <option value="all-season">All Season</option>
        <option value="summer">Sommer</option>
        <option value="winter">Winter</option>
      </select>
      <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
    </MDBCol>

    <MDBCol lg="4" md="6" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${getImage(training.image)})` }"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 font-16"></p>
    </MDBCol>
    <MDBCol lg="4" md="6" class="mb-4">
      <p
        class="training bg-white d-flex align-items-center justify-content-center text-center p-2"
      >
        Wir erweitern regelmäßig unser <br />
        Angebot für Sie! <br /><br />
        Über neue Trainings informieren <br />
        wir Sie in Ihrem Postfach.
      </p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { computed, ref } from "vue";

const filterBy = ref("all-season");
const getImage = (img) => require("../../../assets/img/" + img);
const allTrainings = [
  {
    link: "/training/0",
    image: "schulungen/3.png",
    description: "Reifentechnik Basiswissen",
    type: ["all-season", "summer"],
  },
  {
    link: "/training/1",
    image: "schulungen/4.png",
    description: "Reifentechnik für Fortgeschrittene",
    type: ["all-season", "summer"],
  },
  {
    link: "/training/2",
    image: "schulungen/10.png",
    description: "Produkttraining",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/3",
    image: "schulungen/5.png",
    description: "Technologietraining",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/4",
    image: "schulungen/11.png",
    description: "Einstieg in Reifentechnik und Montage",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/5",
    image: "schulungen/2.png",
    description: "Montageschulung",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/6",
    image: "schulungen/7.png",
    description: "Montageschulung Ohne WDK Zertifkat",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/7",
    image: "schulungen/6.png",
    description: "Reifenreparatur Kaltverfahren",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/8",
    image: "schulungen/9.png",
    description: "Reifenreparatur Heissverfahren",
    type: ["all-season", "winter"],
  },
  {
    link: "/training/9",
    image: "schulungen/8.png",
    description: "Achsvermessung",
    type: ["all-season", "winter"],
  },
];

const trainings = computed(() =>
  allTrainings.filter((training) => training.type.includes(filterBy.value))
);
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.training {
  min-height: 220px;
  background-size: cover;
  background-position: center center;
}

.training-title {
  color: white;
}

.sort-select {
  border: 2px solid $secondaryGray;
  color: $lightGray;
  appearance: none;
  padding: 3px 40px 3px 10px;
  min-width: 240px;
  outline: none;

  @media screen and (max-width: $xxlBreakpoint) {
    min-width: 100px;
  }
}

.sort-select-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.sort-icon {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
