<template>
  <CategoryTop
    banner-image="trainings-overview.jpg"
    title="Preislisten"
    :breadcrumbs-parent-routes="['productInfo']"
    description="
    Laden Sie hier aktuelle Preislisten zu unseren Bridgestone und Firestone Reifen herunter:
    <ul>
      <li>Pkw, 4x4 und Transporter</li>
      <li>Motorrad</li>
      <li>Lkw und Busse</li>
      <li>Landwirtschaft</li>
      <li>Baumaschinen / OTR</li>
    </ul>
"
  />

  <MDBRow>
    <MDBCol lg="4" md="6" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${getImage(training.image)})` }"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 fw-bold font-16"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

const getImage = (img) => require("../../../assets/img/" + img);
const trainings = computed(() => [
  {
    image: "preislisten/Pkw_4x4_Transporter_teaser.jpg",
    link: { name: "pkw" },
    description: "Pkw, 4x4 und Transporter",
  },
  {
    image: "preislisten/motorrrad_teaser.jpg",
    link: { name: "motorrad" },
    description: "Motorrad",
  },
  {
    image: "preislisten/lkw_busse_teaser.jpg",
    link: { name: "lkwUndBusse" },
    description: "Lkw und Busse",
  },
  {
    image: "preislisten/landwirtschaft_teaser.jpg",
    link: { name: "landwirtschaft" },
    description: "Landwirtschaft",
  },
  {
    image: "preislisten/baumaschinen_otr_teaser.jpg",
    link: { name: "baumaschinenOtr" },
    description: "Baumaschinen / OTR",
  },
]);
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.training {
  background-size: cover;
  min-height: 275px;

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 185px;
  }

  @media screen and (max-width: $xlBreakpoint) {
    min-height: 153px;
  }

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 240px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 368px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 67vw;
  }
}

.training-title {
  color: white;
}
</style>
