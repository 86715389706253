<template>
  <MDBRow class="mb-5">
    <MDBCol>
      <h6 class="mb-3">{{ props.question.label }}</h6>
      <div
        v-for="(answer, key) in props.question.answers"
        :key="key"
        class="form-check mb-1 d-flex"
      >
        <input
          :class="`form-check-input ${
            wrongAnswerKey === key || wrongAnswerKey === 'all' ? 'border-danger' : ''
          }`"
          type="radio"
          :name="question.id"
          :id="`${question.id}-${key}`"
          v-model="checked"
          :value="key"
          @change="handleAnswer()"
        />
        <label
          :class="`form-check-label ${
            wrongAnswerKey === key || wrongAnswerKey === 'all' ? 'text-danger' : ''
          }`"
          :for="`${question.id}-${key}`"
        >
          {{ answer.label }}
        </label>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { ref, watch } from "vue";

const checked = ref(false);
const wrongAnswerKey = ref(false);

const props = defineProps({
  question: Object,
  validate: Number,
});

const emit = defineEmits(["validationEffect"]);

watch(props, () => {
  if (checked.value === false && props.validate) {
    wrongAnswerKey.value = "all";
  } else if (checked.value !== false && !props.question.answers[checked.value].correct) {
    wrongAnswerKey.value = checked.value;
  } else {
    wrongAnswerKey.value = false;
  }
});

const handleAnswer = () =>
  emit("validationEffect", props.question.answers[checked.value].correct === true);
</script>

<style scoped lang="scss">
// @import "../../styles/_variables.scss";
</style>
