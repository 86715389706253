<template>
  <MDBCard class="with-category border-0">
    <router-link :to="link ? link : '/shop/products/' + product.id + '/detail'">
      <MDBCardBody>
        <div v-if="props.product.category" class="category">
          {{ props.product.category }}
        </div>

        <MDBCardText>
          <div
            :class="`product-image d-flex align-items-center justify-content-center ${
              props.single ? 'image-fix' : null
            }`"
          >
            <img :src="props.product.imageUrl" class="" alt="product image" />
          </div>

          <p class="font-16 success-green index-order-top">
            für {{ props.product.points }} Punkte
          </p>

          <h6
            class="fw-medium text-dark index-order-top"
            v-html="props.product.name"
          ></h6>

          <p class="success-green font-12 availability">
            Benötigten Punktestand erreicht
          </p>
        </MDBCardText>
      </MDBCardBody>
    </router-link>
  </MDBCard>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { defineProps, computed } from "vue";

const props = defineProps({
  product: Object,
  link: String,
  single: Boolean,
});

const store = useStore();
const user = computed(() => store.getters.getMe);
user;
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.category {
  position: absolute;
  z-index: 2;
  font-size: 18px;
  top: 0;
  left: 0;
  padding: 6px 1.5rem;
  min-width: 172px;
  background: #000;
  color: white;
}

.category:after {
  content: "";
  width: 13px;
  right: -5px;
  top: 1px;
  height: 46px;
  background: white;
  position: absolute;
  transform: rotate(19deg);
}

.with-category {
  padding-top: 40px;
}

.availability {
  position: absolute;
  left: 1.5rem;
  bottom: 15px;
  margin: 0;
}

.card {
  min-height: 100%;
}

.card-body {
  padding-top: 95px;
}

.card-text {
  padding-bottom: 25px;
}

.index-order-top {
  position: relative;
  z-index: 5;
}

.product-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50%;
  height: 150px;
  z-index: 1;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image-fix {
  @media screen and (min-width: ($mdBreakpoint + 1)) and (max-width: $lgBreakpoint) {
    top: 50%;
    transform: translate(0, -50%);
    height: 90%;
  }
}
</style>
