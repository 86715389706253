<template>
  <div class="border-top">
    <p class="copyrights font-10">
      <span class="d-none d-lg-inline">
        ©{{ new Date().getFullYear() }} Bridgestone Solutions. All rights reserved.
      </span>
      <router-link to="/#" class="link hide-mobile-border font-10">
        Cookie-Einstellungen
      </router-link>
      <router-link to="/#" class="link font-10"> Teilnahmebedingungen </router-link>
      <router-link to="/#" class="link font-10"> Impressum</router-link>
      <router-link to="/#" class="link font-10 d-none d-lg-inline">
        Datenschutz
      </router-link>
      <router-link to="/#" class="link font-10 d-none d-lg-inline">
        Nutzungsbedingungen
      </router-link>
      <router-link to="/#" class="link font-10 d-none d-lg-inline">
        Hilfe und Kontakt
      </router-link>
      <span class="d-block d-lg-none">
        ©{{ new Date().getFullYear() }} Bridgestone Solutions. All rights reserved.
      </span>
    </p>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
@import "../../styles/variables";

.border-top {
  border-top: 1px solid $lightGray;
  margin-top: 35px;

  @media screen and (max-width: $mdBreakpoint) {
    margin-top: 0;
  }
}

.link {
  color: $lightGray;
  border-left: 1px solid $lightGray;
  padding-left: 8px;
  margin-left: 8px;
}

.hide-mobile-border {
  @media screen and (max-width: $lgBreakpoint) {
    border-left: 0 solid $lightGray;
    padding-left: 0;
    margin-left: 0;
  }
}

.copyrights {
  color: $lightGray;
  padding-top: 3px;
}
</style>
