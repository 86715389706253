<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Produktbroschüren"
    :breadcrumbs-parent-routes="['productInfo']"
    description="Laden Sie hier aktuelle Broschüren und Flyer zu unseren Bridgestone und Firestone Reifen für Pkw, SUV und Transporter herunter."
  />

  <MDBRow>
    <MDBCol col="lg-4" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center p-3"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="training.description" class="p-4 font-16"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

const trainings = computed(() => [
  {
    name: "Bridgestone",
    link: { name: "bridgestoneProduktbroschuren" },
    description: "Aktuelle Broschüren und Flyer zum Download",
  },
  {
    name: "Firestone",
    link: { name: "firestoneProduktbroschuren" },
    description: "Aktuelle Broschüren und Flyer zum Download",
  },
  {
    name: "Technologien, Markierungen <br/> und mehr",
    link: { name: "technologien" },
    description: "Aktuelle Broschüren und Flyer zum Download",
  },
]);
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.training {
  background: linear-gradient(
    142deg,
    rgba(205, 205, 205, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  min-height: 200px;
}

.training-title {
  color: white;
}
</style>
