<template>
  <MDBRow>
    <MDBCol md="7">
      <MDBCard class="mb-3 mb-md-0">
        <MDBCardBody class="d-flex justify-content-center product-image-wrap">
          <img :src="product.imageUrl" alt="Product image" class="product-image" />
        </MDBCardBody>
      </MDBCard>
    </MDBCol>

    <MDBCol md="5">
      <MDBCard class="product-main-data">
        <MDBCardBody class="d-flex flex-column justify-content-between">
          <div>
            <MDBCardTitle class="font-25">{{ product.name }}</MDBCardTitle>
            <MDBCardTitle class="font-45 my-5 text-success">
              {{ product.points }}
              <span class="font-25 text-dark">punkte</span>
            </MDBCardTitle>
          </div>
          <MDBBtn color="success" class="w-100" @click.prevent="addProduct(product.id)">
            In den Warenkorb
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>

  <MDBRow>
    <MDBCol col="12">
      <MDBCard class="product-details">
        <MDBCardBody>
          <div v-html="product.description"></div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

watch(
  () => route.params.id,
  (id) => {
    if (id) {
      store.dispatch("me");
      store.dispatch("loadProductById", id);
      store.dispatch("clearAttributes");
    }
  }
);

const addProduct = async (productId) => {
  const data = {
    id: productId,
    quantity: 1,
  };
  await store.dispatch("addItem", data);
};

store.dispatch("me");
store.dispatch("loadProductById", route.params.id);
store.dispatch("clearAttributes");

const product = computed(() => store.getters.getProduct);
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.product-image-wrap {
  height: 500px;
}
.product-image {
  max-height: 100%;
}

.product-main-data {
  min-height: 500px;
}

.product-details {
  margin-top: 78px;

  @media screen and (max-width: $mdBreakpoint) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style>
