<template>
  <div class="background-wrap">
    <div class="background"></div>
  </div>

  <MDBRow class="login-container">
    <MDBCol lg="6">
      <div class="d-flex justify-content-center align-items-center slider-col">
        <div class="h-auto w-100">
          <TextSlider :slides="slides" />
        </div>
      </div>
    </MDBCol>
    <MDBCol lg="6">
      <div class="d-flex justify-content-center align-items-center text-slider-wrap">
        <form @submit.prevent="onSubmit" class="w-100 box-shadow">
          <MDBCard class="border-0">
            <MDBCardBody>
              <MDBCardTitle class="text-start font-35 mb-4">Anmeldung</MDBCardTitle>
              <MDBCardText class="pb-0">
                <label>Email</label>
                <input type="text" v-model="form.username" />
                <label>Passwort</label>
                <input v-model="form.password" type="password" />

                <div class="text-end d-flex justify-content-end align-items-center pt-4">
                  <router-link to="/register" class="me-2 d-inline-block">
                    Ich habe noch kein Konto
                  </router-link>
                  <MDBBtn type="submit" color="primary">Login</MDBBtn>
                </div>
                <p
                  v-if="loginError"
                  class="text-danger text-small text-end m-0 pt-2 text-small"
                >
                  Falsche Anmeldedaten, bitte versuchen Sie es erneut!
                </p>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </form>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import TextSlider from "./General/TextSlider/TextSlider.vue";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";

const store = useStore();
const loginError = ref(false);
const slides = [
  {
    image: "logo-white.svg",
    description:
      "<br/> Herzlich willkommen bei Bridgestone Plus! <br/> <br/> Bitte melden Sie sich mit Ihren Zugangsdaten an oder erstellen Sie ein neues Nutzerkonto.",
  },
];

const form = ref({
  username: "",
  password: "",
});

const onSubmit = async () => {
  try {
    await store.dispatch("loginUser", {
      email: form.value.username,
      password: form.value.password,
    });
  } catch (e) {
    loginError.value = true;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.background-wrap {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}
.background {
  background: $dangerRed;
  height: 500vh;
  width: 300vw;
  position: absolute;
  transform: rotate(16deg) translate(-50%, -50%);
  top: 0;
  right: -85%;

  @media screen and (max-width: $lgBreakpoint) {
    position: fixed;
  }
}

.login-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.login-container form {
  width: 100%;
  max-width: 390px;
}

.slider-col {
  height: 100vh;

  @media screen and (max-width: $lgBreakpoint) {
    margin-top: 100px;
    height: auto;
  }

  > div {
    @media screen and (max-width: $lgBreakpoint) {
      max-width: 390px;
    }
  }
}

.text-slider-wrap {
  height: 100vh;

  @media screen and (max-width: $lgBreakpoint) {
    height: auto;
    margin-bottom: 100px;
  }
}
</style>
