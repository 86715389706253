<template>
  <MDBRow>
    <MDBCol class="mt-5">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" v-for="(link, key) in meta.links" v-bind:key="key">
            <button
              :class="`page-link font-16 
                ${link.url === null ? 'disabled' : ''} 
                ${link.active ? 'active' : ''}`"
              @click="changeProductPage(key)"
              type="button"
              v-html="link.label"
            ></button>
          </li>
        </ul>
      </nav>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { defineEmits } from "vue";

const store = useStore();
const meta = store.getters.getProductsMeta;
const emit = defineEmits(["pageNumberChanged"]);

function changeProductPage(key) {
  const url = meta.links[key].url;

  if (url === null) {
    return;
  }

  const pageNumber = url.match(/\d*$/g)[0];
  emit("pageNumberChanged", pageNumber);
}
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.page-link {
  background: white;
  margin: 0 5px;
  border-radius: 0px;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.active {
  background: $dangerRed;
  color: white;
}
</style>
