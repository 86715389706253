<template>
  <CategoryTop
    :title="categoriesData.current ? categoriesData.current.name : ''"
    banner-image="schulungen-banner.png"
    :description="categoriesData.current ? categoriesData.current.description : ''"
    :loader="categoriesRequestSent"
    :breadcrumbs-parent-routes="getBreadcrumbParentRoutes"
    :breadcrumbs-route-name="categoriesData.current ? categoriesData.current.name : ' '"
  />

  <CategoryFilter
    @sort-changed="(sortValue) => (sortBy = sortValue)"
    :loader="categoriesRequestSent"
  />

  <ProductList
    :products="products"
    :loader="productRequestSent"
    @page-number-changed="(e) => pageNumberChanged(e)"
  />
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import CategoryFilter from "./CategoriesFilter.vue";
import { useRoute } from "vue-router";
import ProductList from "./ProductList.vue";
import { getProducts, getCategoriesData } from "./products";

const store = useStore();
const sortBy = ref("lowest");
const productRequestSent = computed(() => store.getters.getProductRequestSent);
const categoriesRequestSent = computed(() => store.getters.getCategoriesRequestSent);
const route = useRoute();
const categoryId = computed(() => route.params.id);
const categoriesData = computed(() => getCategoriesData());
const products = computed(() => getProducts(sortBy));

const loadProducts = () => {
  store.dispatch("loadCategoryProduct", categoryId.value);
};

const pageNumberChanged = (e) => store.dispatch("loadProducts", e);

const getBreadcrumbParentRoutes = computed(() => {
  const routes = ["shop"];

  if (categoriesData.value.parent) {
    routes.push({
      name: "shopCategory",
      title: categoriesData.value.parent.name,
      params: {
        id: categoriesData.value.parent.id,
      },
    });
  }

  return routes;
});

watch(categoryId, () => {
  loadProducts();
});

loadProducts();
</script>

<style scoped lang="scss"></style>
