<template>
  <MDBRow>
    <MDBCol col="lg-8 slider-wrap" class="pb-3">
      <Slider :slides="slides" />
    </MDBCol>

    <MDBCol col="lg-4" class="column-order">
      <MDBCard class="card-dark mb-3">
        <MDBCardBody class="d-flex flex-column justify-content-center">
          <router-link :to="{ name: 'trainings' }">
            <img src="../../assets/img/icon-wheel.svg" alt="icon" class="item-icon" />
            <MDBCardTitle class="mb-0 black-item-title">
              Bridgestone <br />
              Trainings
            </MDBCardTitle>
          </router-link>
        </MDBCardBody>
      </MDBCard>

      <MDBCard class="card-dark mb-3">
        <MDBCardBody class="d-flex flex-column justify-content-center">
          <router-link :to="{ name: 'productInfo' }">
            <img src="../../assets/img/icon-tire.svg" alt="icon" class="item-icon" />
            <MDBCardTitle class="mb-0 black-item-title">
              Produkte, <br />
              Preislisten und mehr
            </MDBCardTitle>
          </router-link>
        </MDBCardBody>
      </MDBCard>

      <MDBCard class="card-dark mb-3">
        <MDBCardBody class="d-flex flex-column justify-content-center">
          <img src="../../assets/img/icon-speedometer.svg" alt="icon" class="item-icon" />
          <MDBCardTitle class="mb-0 black-item-title">
            Abverkaufs-<br />unterstützung
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>

  <MDBRow>
    <MDBCol col="lg-12" class="my-3">
      <h5>
        <router-link to="/shop" class="text-dark">Bridgestone Prämienshop</router-link>
      </h5>
      <p class="text-lg-end font-12 fw-bold all-products">
        <router-link to="/shop">
          ALLE PRODUKTE ANZEIGEN <MDBIcon icon="chevron-right" size="1x" />
        </router-link>
      </p>
    </MDBCol>
  </MDBRow>

  <MDBRow>
    <MDBCol col="lg-4" class="mb-3 mb-lg-0">
      <ProductItem :product="product" link="/shop" single />
    </MDBCol>

    <MDBCol col="lg-4" class="mb-3 mb-lg-0">
      <ProductItem :product="product2" link="/shop" single />
    </MDBCol>

    <MDBCol col="lg-4" class="mb-3 mb-lg-0">
      <ProductItem :product="product3" link="/shop" single />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import ProductItem from "../Shop/ProductListItem.vue";
import Slider from "./Slider.vue";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";

const product = {
  id: 1221,
  name: "Reifen-Profilmesser<br/> (10 Stück)",
  category: "Meistgekauft",
  points: 40,
  imageUrl: require("../../assets/img/profilmesser.png"),
};

const product2 = {
  id: 1221,
  name: 'ESPRIT Taschenschirm "Mini Basic",<br/> schwarz',
  category: "Saisonales",
  points: 40,
  imageUrl: require("../../assets/img/taschenschrim.png"),
};

const product3 = {
  id: 1221,
  name: "BOSE Soundlink Colour Bluetooth®<br>Speaker II",
  category: "Fast geschafft",
  points: 1400,
  imageUrl: require("../../assets/img/bose-box.png"),
};

const slides = [
  {
    title: "",
    description: "",
    background: "01_Test_Komm_Winter_22_hero_784x514.jpg",
  },
  {
    title: "",
    description: "",
    background: "02_bs_olympia_ambassabor_hero_784x514.jpg",
  },
  {
    title: "Neue Prämienwelt",
    description: "Entdecken Sie die neue Bridgestone <br>Olympia Kollektion",
    background: "03_Test_Komm_Winterwerbemittel_praemienshop_22_hero_784x514.jpg",
    color: "dark",
  },
  {
    title: "Bridgestone PLUS entdecken",
    description:
      "Starten Sie die Entdeckungstour um Alles über <br> unsere neue Bridgestone-PLUS Plattform zu erfahren!",
    background: "bs-big.png",
  },
];
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.all-products {
  margin: -25px 0 0 0;

  @media screen and (max-width: $lgBreakpoint) {
    margin: 1rem 0 0 0;
  }

  a {
    color: $darkGray;
  }

  i {
    padding-left: 5px;
  }
}

.item-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 1;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
}

.black-item-title {
  position: relative;
  z-index: 2;
}

.slider-wrap {
  min-height: 514px;

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 472px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 354px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 65vw;
  }
}
</style>
