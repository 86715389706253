<template>
  <MDBRow class="mb-5">
    <MDBCol lg="7" class="mb-4 image-col">
      <img
        v-if="trainingData && trainingData.image"
        :src="getImage(trainingData.image)"
        alt=""
      />
      <iframe
        v-if="trainingData.vimeo"
        class="vimeo"
        frameborder="0"
        :src="trainingData.vimeo"
        allow="fullscreen"
        title=""
        trainingData-ready="true"
        tabindex="-1"
      ></iframe>
    </MDBCol>
    <MDBCol col="5" class="mb-4">
      <h4 v-if="trainingData" v-html="trainingData.name"></h4>
      <p v-if="trainingData" v-html="trainingData.details"></p>
    </MDBCol>
  </MDBRow>

  <form method="POST" action="/" class="form">
    <div v-for="(question, index) in questionsData" :key="index">
      <component
        v-bind:is="getComponent(question.type)"
        v-bind:props="question"
        v-bind="{ question, validate }"
        @validation-effect="(e) => handleValidationEffect(e, index)"
      />
    </div>

    <MDBRow>
      <MDBCol>
        <Button class="btn btn-danger mt-3" type="button" @click="(e) => handleSubmit(e)">
          submit
        </Button>
      </MDBCol>
    </MDBRow>
  </form>
</template>

<script setup>
import { useRoute } from "vue-router";
import db from "./db";
import trainingDb from "../Single/db";
import Radio from "./Inputs/Radio.vue";
import Checkbox from "./Inputs/Checkbox.vue";
import Text from "./Inputs/Text.vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { ref } from "vue";

const route = useRoute();
const questionsData = ref(db[route.params.id]);
const trainingData = ref(trainingDb[route.params.id]);
let wrongAnswers = questionsData.value
  .map((question, key) => (question.type !== "text" ? key : null))
  .filter((item) => item !== null);

const validate = ref(0);

const handleSubmit = (e) => {
  e.preventDefault();
  validate.value = validate.value + 1;

  if (wrongAnswers.length === 0) {
    console.log("submited succesfully");
  }
};

const getComponent = (type) => {
  switch (type) {
    case "checkbox":
      return Checkbox;

    case "radio":
      return Radio;

    case "text":
      return Text;
  }
};

const handleValidationEffect = (correctAnswer, key) => {
  if (!correctAnswer && !wrongAnswers.includes(key)) {
    wrongAnswers.push(key);
  } else if (correctAnswer && wrongAnswers.includes(key)) {
    wrongAnswers = wrongAnswers.filter((item) => item !== key);
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.vimeo {
  width: 100%;
  height: 500px;
}

.image-col {
  min-height: 500px;

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: auto;
  }
}
</style>
