<template>
  <router-view />
</template>

<script setup></script>

<style lang="scss">
@import "./styles/fonts";
@import "./styles/global";
</style>
