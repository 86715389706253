<template>
  <CategoryTop
    banner-image="preislisten/header_beanstandungs_formular_1192x282â__.jpg"
    title="Beanstandungs-Formular"
    :breadcrumbs-parent-routes="['productInfo']"
    description="Füllen Sie das nachfolgende Formular für Beanstandungen zu unseren Reifen  aus:"
  />

  <form class="form">
    <MDBRow>
      <MDBCol>
        <h3 class="mb-4">SCHADENSTYP</h3>
        <p>Sachschaden</p>
        <div
          class="btn-group mb-3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="sachschaden"
            id="sachschaden1"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="sachschaden1">Ja</label>

          <input
            type="radio"
            class="btn-check"
            name="sachschaden"
            id="sachschaden2"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="sachschaden2">Nein</label>
        </div>

        <p>Personenschaden</p>
        <div
          class="btn-group mb-3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="personenschaden"
            id="personenschaden1"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="personenschaden1">Ja</label>

          <input
            type="radio"
            class="btn-check"
            name="personenschaden"
            id="personenschaden2"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="personenschaden2">Nein</label>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3">ALLGEMEIN</h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ordnungsbegriff des Händlers"
            aria-label="Ordnungsbegriff des Händlers"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ihre E-Mailadresse*"
            aria-label="Ihre E-Mailadresse*"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Land*</option>
          <option value="1">Deutschland</option>
          <option value="2">Österreich</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Abholort*</option>
          <option value="1">Reifenhändler / Autohaus</option>
          <option value="2">Zwischenhändler</option>
          <option value="3">vor Ort Begutachtung (nur AS)</option>
        </select>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3">ANSCHRIFT REIFENHÄNDLER = REGULIERER</h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Kundennummer (falls vorhanden)"
            aria-label="Kundennummer (falls vorhanden)"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Anrede</option>
          <option value="1">Herr</option>
          <option value="2">Frau</option>
          <option value="3">Firma</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Name / Firma"
            aria-label="Name / Firma"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Straße & Hausnr."
            aria-label="Straße & Hausnr."
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="PLZ"
            aria-label="PLZ"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ort"
            aria-label="Ort"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Telefon"
            aria-label="Telefon"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ansprechpartner"
            aria-label="Ansprechpartner"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3">ANSCHRIFT ZWISCHENHÄNDLER</h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Anrede</option>
          <option value="1">Herr</option>
          <option value="2">Frau</option>
          <option value="3">Firma</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Name / Firma"
            aria-label="Name / Firma"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>

      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Straße & Hausnr."
            aria-label="Straße & Hausnr."
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="PLZ"
            aria-label="PLZ"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ort"
            aria-label="Ort"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Telefon"
            aria-label="Telefon"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ansprechpartner"
            aria-label="Ansprechpartner"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3">ANGABEN ZUM FAHRZEUG</h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option value="" selected="selected">Fahrzeug/Reifen*</option>
          <option value="neu_nicht_montiert">Neuware (nicht montiert)</option>
          <option value="neu_montiert">Neuware (montiert)</option>
          <option value="frontantrieb">Frontantrieb</option>
          <option value="heckantrieb">Heckantrieb</option>
          <option value="allrad">Allrad</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Fahrzeughersteller"
            aria-label="Fahrzeughersteller"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Fahrzeugmodell und Typbezeichnung lt. Zulassung"
            aria-label="Fahrzeugmodell und Typbezeichnung lt. Zulassung"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Erstzulassung (TT.MM.JJJJ)"
            aria-label="Erstzulassung (TT.MM.JJJJ)"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="number"
            class="form-control"
            placeholder="Motorleistung (KW z.B. 150)"
            aria-label="Motorleistung (KW z.B. 150)"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Erstausrüstung</option>
          <option value="1">Ja</option>
          <option value="2">Nein</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <select
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Laufleistung Reifen (km) / Betriebsstunden</option>
          <option value="1">Laufleistung Reifen (km)</option>
          <option value="2">Betriebsstunden</option>
        </select>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input
            type="number"
            class="form-control"
            placeholder="Km oder Stunden (z.B. 2000)"
            aria-label="Km oder Stunden (z.B. 2000)"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
    </MDBRow>
    <ReifenFormItem :id="1" :values="reifen1Values" />
    <ReifenFormItem :id="2" :values="reifen2Values" />
    <div class="text-end">
      <button
        type="button"
        class="btn btn-dark"
        @click="() => (reifen2Values = copyReifen(reifen1Values))"
      >
        Reifen 1 kopieren
      </button>
    </div>
    <ReifenFormItem :id="3" :values="reifen3Values" />
    <div class="text-end">
      <button
        type="button"
        class="btn btn-dark"
        @click="() => (reifen3Values = copyReifen(reifen1Values))"
      >
        Reifen 1 kopieren
      </button>
    </div>
    <ReifenFormItem :id="4" :values="reifen4Values" />
    <div class="text-end">
      <button
        type="button"
        class="btn btn-dark"
        @click="() => (reifen4Values = copyReifen(reifen1Values))"
      >
        Reifen 1 kopieren
      </button>
      <button
        type="button"
        class="btn btn-dark"
        @click="() => (reifen4Values = copyReifen(reifen3Values))"
      >
        Reifen 3 kopieren
      </button>
    </div>

    <MDBRow>
      <MDBCol>
        <h3 class="mt-3">SCHADENDETAILS</h3>
        <p class="mb-3">Beanstandungsgrund*</p>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <div class="d-flex flex-wrap">
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_0"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="vibration"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_0"
              >1. Vibration
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_1"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="nicht_auswuchtbar"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_1"
              >2. Nicht auswuchtbar
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_2"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="geraeusche"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_2"
              >3. Geräusche
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_3"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="luftdruckverlust"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_3"
              >4. Luftdruckverlust
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_4"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="abriebsbild"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_4"
              >5. Abriebsbild
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_5"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="laufleistung"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_5"
              >6. Laufleistung
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_6"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="beulen_verformungen"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_6"
              >7. Beulen / Verformungen
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_7"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="profilausbrueche"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_7"
              >8. Profilausbrüche
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_8"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="risse"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_8"
              >9. Risse
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_9"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="fahrverhalten"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_9"
              >10. Fahrverhalten
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_10"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="ziehen"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_10"
              >11. Ziehen
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_11"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="nasshaftung"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_11"
              >12. Nasshaftung
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_12"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="defekte_neuware"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_12"
              >13. Defekte Neuware (bitte Lieferschein Nr. unter „Beanstandungsgrund Text“
              angeben)
            </label>
          </div>
          <div class="form-check mx-1">
            <input
              type="checkbox"
              id="claim_schaden_beanstandungscodes_13"
              name="claim[schaden][beanstandungscodes][]"
              class="form-check-input active"
              value="anderer_grund"
            />
            <label class="form-check-label" for="claim_schaden_beanstandungscodes_13"
              >14. Anderer Grund (bitte unter „Beanstandungsgrund Text“ angeben)
            </label>
          </div>
        </div>
      </MDBCol>
      <MDBCol col="12">
        <div class="input-group my-3">
          <input
            type="number"
            class="form-control"
            placeholder="Beanstandungsgrund Text max. 160 Zeichen"
            aria-label="Beanstandungsgrund Text max. 160 Zeichen"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <p>Wurden die Reifen bereits ersetzt?*</p>
        <div
          class="btn-group mb-3"
          role="group"
          aria-label="Wurden die Reifen bereits ersetzt?*"
        >
          <input
            type="radio"
            class="btn-check"
            name="tyre-change"
            id="tyre-change1"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="tyre-change1">Ja</label>

          <input
            type="radio"
            class="btn-check"
            name="tyre-change"
            id="tyre-change2"
            autocomplete="off"
          />
          <label class="btn btn-outline-danger" for="tyre-change2">Nein</label>
        </div>
      </MDBCol>

      <MDBCol col="12">
        <div class="input-group my-3">
          <input
            type="number"
            class="form-control"
            placeholder="Falls ja, Marke der Ersatzreifen"
            aria-label="Falls ja, Marke der Ersatzreifen"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <div class="input-group mt-3">
          <input
            type="number"
            class="form-control"
            placeholder="Name des Sachbearbeiters*"
            aria-label="Name des Sachbearbeiters*"
            aria-describedby="basic-addon1"
          />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3">ABSCHLUSS</h3>
        <p>
          Diese Angaben sind vollständig und wurden nach bestem Wissen gemacht. Es wird
          versichert, dass die beanstandete Bereifung auf vorgenanntem Fahrzeug gefahren
          wurde. <br />
          <br />
          <b>Die Bereifung kann zu Prüfzwecken geschnitten werden. </b> <br /><br />

          <b
            >Bei Anerkennung der Beanstandung behalten wir uns vor, die Bereifung nach
            wertanteiliger Gutschrift zu erstatten, oder durch eine kostenlose
            Ersatzlieferung zu kompensieren.</b
          >
          <br />
          Die anerkannte Bereifung wird verschrottet. Bei Ablehnung kann diese zurück
          gefordert werden. <br />
          <br />
          Die Speicherung o.g. personenbezogener Daten wird gestattet.
        </p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="regulation1" />
          <label class="form-check-label" for="regulation1">
            Vereinbarung gelesen und akzeptiert.*
          </label>
        </div>
        <a
          href="http://terms-de.bridgestoneemea.com/"
          target="_blank"
          class="d-block my-2"
          >Allgemeine Geschäftsbedingungen lesen.</a
        >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="regulation2" />
          <label class="form-check-label" for="regulation2">
            AGB gelesen und akzeptiert.*
          </label>
        </div>

        <button type="submit" disabled class="btn btn-danger mt-5 mb-1">
          Abschicken
        </button>
        <p>*Pflichtfeld</p>
      </MDBCol>
    </MDBRow>
  </form>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import ReifenFormItem from "./ReifenFormItem.vue";
import { ref } from "vue";

const reifen1Values = ref({
  name: 0,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serialNumber: "",
});

const reifen2Values = ref({
  name: 0,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serialNumber: "",
});

const reifen3Values = ref({
  name: 0,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serialNumber: "",
});

const reifen4Values = ref({
  name: 0,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serialNumber: "",
});

const copyReifen = (reifen) => {
  return {
    name: reifen.name,
    size: reifen.size,
    index: reifen.index,
    profile: reifen.profile,
    dot: reifen.dot,
    serialNumber: reifen.serialNumber,
  };
};
</script>

<style scoped lang="scss">
.form {
  .form-control,
  .form-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    height: auto;
  }

  select {
    option:first-child {
      display: none;
    }
  }
}
</style>
