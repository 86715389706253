import ProductInfo from "@/components/ProductInfo/index.vue";
import Default from '../views/Default.vue';
import Pkw from "@/components/ProductInfo/Preislisten/Pkw.vue";
import Motorrad from "@/components/ProductInfo/Preislisten/Motorrad.vue";
import LkwUndBusse from "@/components/ProductInfo/Preislisten/LkwUndBusse.vue";
import Landwirtschaft from "@/components/ProductInfo/Preislisten/Landwirtschaft.vue";
import BaumaschinenOtr from "@/components/ProductInfo/Preislisten/BaumaschinenOtr.vue";
import Produktbroschuren from "@/components/ProductInfo/Produktbroschuren/index.vue";
import BridgestoneProduktbroschuren from "@/components/ProductInfo/Produktbroschuren/BridgestoneProduktbroschuren.vue";
import FirestoneProduktbroschuren from "@/components/ProductInfo/Produktbroschuren/FirestoneProduktbroschuren.vue";
import Technologien from "@/components/ProductInfo/Produktbroschuren/Technologien.vue";
import Pricat from "@/components/ProductInfo/Pricat.vue";
import BDrive from "@/components/ProductInfo/BDrive.vue";
import BeanstandungsFormular from "@/components/ProductInfo/BeanstandungsFormular.vue";
import Preislisten from "@/components/ProductInfo/Preislisten/index.vue";

export default [
  {
    path: "/produkte-preislisten-und-mehr",
    name: "productInfo",
    component: Default,
    props: {
      content: ProductInfo,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/produktbroschuren",
    name: "produktbroschuren",
    component: Default,
    props: {
      content: Produktbroschuren,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/produktbroschuren/bridgestone",
    name: "bridgestoneProduktbroschuren",
    component: Default,
    props: {
      content: BridgestoneProduktbroschuren,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/produktbroschuren/firestone",
    name: "firestoneProduktbroschuren",
    component: Default,
    props: {
      content: FirestoneProduktbroschuren,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/produktbroschuren/technologien-markierungen-und-mehr",
    name: "technologien",
    component: Default,
    props: {
      content: Technologien,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/pricat",
    name: "pricat",
    component: Default,
    props: {
      content: Pricat,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/b-drive",
    name: "bDrive",
    component: Default,
    props: {
      content: BDrive,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/beanstandungs-formular",
    name: "beanstandungsFormular",
    component: Default,
    props: {
      content: BeanstandungsFormular,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten",
    name: "preislisten",
    component: Default,
    props: {
      content: Preislisten,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten/pkw",
    name: "pkw",
    component: Default,
    props: {
      content: Pkw,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten/motorrad",
    name: "motorrad",
    component: Default,
    props: {
      content: Motorrad,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten/lkw-und-busse",
    name: "lkwUndBusse",
    component: Default,
    props: {
      content: LkwUndBusse,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten/landwirtschaft",
    name: "landwirtschaft",
    component: Default,
    props: {
      content: Landwirtschaft,
      switchOrderMobile: true
    }
  },
  {
    path: "/produkte-preislisten-und-mehr/preislisten/baumaschinen-otr",
    name: "baumaschinenOtr",
    component: Default,
    props: {
      content: BaumaschinenOtr,
      switchOrderMobile: true
    }
  },
];
