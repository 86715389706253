import { useStore } from "vuex";
import { useRoute } from "vue-router";

export const getProducts = (sortBy) => {
  const store = useStore();
  let products = store.getters.getProducts;

  products = products.filter((product) => !product.isVariant);

  // products.sort((a, b) => {
  //   const aPoints = parseInt(a.points);
  //   const bPoints = parseInt(b.points);

  //   if (sortBy.value === "lowest") {
  //     return aPoints < bPoints ? -1 : 1;
  //   } else {
  //     return aPoints > bPoints ? -1 : 1;
  //   }
  // });
  sortBy;

  return products;
};

export const getCategoriesData = () => {
  const store = useStore();
  const route = useRoute();
  let currentCategory = null;
  let parentCategory = null;

  if (store.getters.getCategories.length) {
    store.getters.getCategories.forEach((category) => {
      category.id === route.params.id ? (currentCategory = category) : null;

      category.children.forEach((childCategory) => {
        if (childCategory.id === route.params.id) {
          currentCategory = childCategory;
          parentCategory = category;
        }
      });
    });
  }

  return { current: currentCategory, parent: parentCategory };
};
