<template>
  <MDBRow>
    <MDBCol>
      <h3 class="my-3">REIFEN {{ props.id }}</h3>
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol md="6">
      <select
        class="form-select form-select-lg mb-3"
        aria-label=".form-select-lg example"
        v-model="data.name"
      >
        <option selected value="0">Reifenposition*</option>
        <option value="keine">Nicht bekannt</option>
        <optgroup label="PKW/SUV/TRAKTOR">
          <option value="pst_vorne_links">P/S/T-vorne links</option>
          <option value="pst_vorne_rechts">P/S/T-vorne rechts</option>
          <option value="pst_hinten_links">P/S/T-hinten links</option>
          <option value="pst_hinten_rechts">P/S/T-hinten rechts</option>
        </optgroup>
        <optgroup label="MOTORRAD">
          <option value="mot_vorne">MOT-vorne</option>
          <option value="mot_hinten">MOT-hinten</option>
        </optgroup>
        <optgroup label="LKW SZM">
          <option value="lkw_lenkachse_1_links">LKW-Lenkachse 1 links</option>
          <option value="lkw_lenkachse_1_rechts">LKW-Lenkachse 1 rechts</option>
          <option value="lkw_lenkachse_2_links">LKW-Lenkachse 2 links</option>
          <option value="lkw_lenkachse_2_rechts">LKW-Lenkachse 2 rechts</option>
          <option value="lkw_antriebsachse_1_links_aussen">
            LKW-Antriebsachse 1 links außen
          </option>
          <option value="lkw_antriebsachse_1_links_innen">
            LKW-Antriebsachse 1 links innen
          </option>
          <option value="lkw_antriebsachse_1_rechts_aussen">
            LKW-Antriebsachse 1 rechts außen
          </option>
          <option value="lkw_antriebsachse_1_rechts_innen">
            LKW-Antriebsachse 1 rechts innen
          </option>
          <option value="lkw_antriebsachse_2_links_aussen">
            LKW-Antriebsachse 2 links außen
          </option>
          <option value="lkw_antriebsachse_2_links_innen">
            LKW-Antriebsachse 2 links innen
          </option>
          <option value="lkw_antriebsachse_2_rechts_aussen">
            LKW-Antriebsachse 2 rechts außen
          </option>
          <option value="lkw_antriebsachse_2_rechts_innen">
            LKW-Antriebsachse 2 rechts innen
          </option>
          <option value="lkw_nachlaufachse_links">LKW-Nachlaufachse links</option>
          <option value="lkw_nachlaufachse_rechts">LKW-Nachlaufachse rechts</option>
        </optgroup>
        <optgroup label="ANHÄNGER/AUFLIEGER">
          <option value="trailer_achse_1_links_aussen">
            TRAILER-Achse 1 links außen
          </option>
          <option value="trailer_achse_1_links_innen">TRAILER-Achse 1 links innen</option>
          <option value="trailer_achse_1_rechts_aussen">
            TRAILER-Achse 1 rechts außen
          </option>
          <option value="trailer_achse_1_rechts_innen">
            TRAILER-Achse 1 rechts innen
          </option>
          <option value="trailer_achse_2_links_aussen">
            TRAILER-Achse 2 links außen
          </option>
          <option value="trailer_achse_2_links_innen">TRAILER-Achse 2 links innen</option>
          <option value="trailer_achse_2_rechts_aussen">
            TRAILER-Achse 2 rechts außen
          </option>
          <option value="trailer_achse_2_rechts_innen">
            TRAILER-Achse 2 rechts innen
          </option>
          <option value="trailer_achse_3_links_aussen">
            TRAILER-Achse 3 links außen
          </option>
          <option value="trailer_achse_3_links_innen">TRAILER-Achse 3 links innen</option>
          <option value="trailer_achse_3_rechts_aussen">
            TRAILER-Achse 3 rechts außen
          </option>
          <option value="trailer_achse_3_rechts_innen">
            TRAILER-Achse 3 rechts innen
          </option>
        </optgroup>
      </select>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Reifengröße (z.B. 215/60/16)"
          aria-label="Reifengröße (z.B. 215/60/16)"
          aria-describedby="basic-addon1"
          v-model="data.size"
        />
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Geschwindigkeits- / Lastindex*"
          aria-label="Geschwindigkeits- / Lastindex*"
          aria-describedby="basic-addon1"
          v-model="data.index"
        />
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Profilbezeichnung inc. Spezifikation ( z.B.: RE050A, R168 Eco, …)*"
          aria-label="Profilbezeichnung inc. Spezifikation ( z.B.: RE050A, R168 Eco, …)*"
          aria-describedby="basic-addon1"
          v-model="data.profile"
        />
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="DOT (bitte ALLE 10-11 Stellen angeben)*"
          aria-label="DOT (bitte ALLE 10-11 Stellen angeben)*"
          aria-describedby="basic-addon1"
          v-model="data.dot"
        />
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Serien-Nr. (nur bei LKW Reifen)"
          aria-label="Serien-Nr. (nur bei LKW Reifen)"
          aria-describedby="basic-addon1"
          v-model="data.serialNumber"
        />
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { defineProps, ref, computed } from "vue";

const props = defineProps({
  id: Number,
  values: Object,
});

const data = computed(() => {
  return props.values
    ? props.values
    : ref({
        name: 0,
        size: "",
        index: "",
        profile: "",
        dot: "",
        serialNumber: "",
      });
});
</script>

<style scoped lang="scss">
.form {
  .form-control,
  .form-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    height: auto;
  }

  select {
    option:first-child {
      display: none;
    }
  }
}
</style>
