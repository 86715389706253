<template>
  <MDBRow>
    <MDBCol lg="7" class="mb-4 image-col">
      <img v-if="data && data.image" :src="getImage(data.image)" alt="" />
      <iframe
        v-if="data.vimeo"
        class="vimeo"
        frameborder="0"
        :src="data.vimeo"
        allow="fullscreen"
        title=""
        data-ready="true"
        tabindex="-1"
      ></iframe>
    </MDBCol>
    <MDBCol col="5" class="mb-4">
      <h4 v-if="data" v-html="data.name"></h4>
      <p v-if="data" v-html="data.details"></p>
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol lg="12" class="mt-5">
      <div v-if="data" v-html="data.description"></div>
      <router-link
        :to="{ name: 'trainingForm', id: route.params.id }"
        class="btn btn-danger text-white mt-4"
        >Zum Quiz</router-link
      >
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { ref } from "vue";
import db from "./db";
import { useRoute } from "vue-router";

const route = useRoute();
const getImage = (img) => require("../../../assets/img/" + img);
const data = ref(route.params.id !== "online-training" ? db[route.params.id] : null);
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.vimeo {
  width: 100%;
  height: 500px;
}

.image-col {
  min-height: 500px;

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: auto;
  }
}
</style>
