<template>
  <MDBCol lg="4" md="6" class="mb-4">
    <a
      :href="`/files/preislist/${props.file.file}`"
      :download="props.file.file"
      class="file btn btn-dark d-flex align-items-center justify-content-stretch position-relative w-100 p-4"
    >
      <img
        v-if="props.file.cover"
        :src="getImage(props.file.cover)"
        class="cover"
        alt="cover"
      />

      <MDBIcon
        :icon="`file-${props.file.type}`"
        class="icon-extension d-flex align-items-center justify-content-center"
      />
      <MDBRow class="align-items-center text-start w-100">
        <MDBCol col="3" class="text-center">
          <MDBIcon
            icon="download"
            class="icon d-inline-flex align-items-center justify-content-center"
          />
        </MDBCol>
        <MDBCol col="7">
          <h6 v-html="props.file.name" class="file-title text-white"></h6>
          <p
            v-if="props.file.description"
            v-html="props.file.description"
            class="fw-bold mb-0"
          ></p>
          <p v-html="props.file.date" class="fw-bold mb-0"></p>
        </MDBCol>
      </MDBRow>
    </a>
  </MDBCol>
</template>

<script setup>
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { defineProps } from "vue";

const props = defineProps({
  file: Object,
});

// const getFile = (file) => require("../../assets/files/preislist/" + file);
const getImage = (file) => require("../../assets/img/preislisten/" + file);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.icon {
  font-size: 20px;
  background: white;
  color: $darkGray;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  transform: scale(1);
  transition: 150ms ease-in-out;
}

.icon-extension {
  display: none;
  font-size: 20px;
  background: white;
  color: $darkGray;
  height: 40px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: $xxxlBreakpoint) and (min-width: ($xlBreakpoint + 1)) {
    height: 30px;
    width: 60px;
    font-size: 17px;
  }

  @media screen and (max-width: $xlBreakpoint) and (min-width: $lgBreakpoint) {
    height: 20px;
    width: 40px;
    font-size: 12px;
  }
}

.file {
  min-height: 150px;
  height: 100%;
  background-color: $darkGray;
  z-index: 9;

  &:hover {
    background: darken($darkGray, 5%);
    .icon {
      transform: scale(1.1);
      color: $dangerRed;
    }
  }
}

.cover {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 10;
}
</style>
