
import SingleColumn from "../views/SingleColumn.vue";
import User from '../components/User/index.vue'
import PointsOverview from '../components/User/PointsOverview.vue'
import Stammdaten from '../components/User/Stammdaten.vue'
import Mailbox from '../components/User/Mailbox.vue'

export default [
  {
    path: "/account",
    name: "account",
    component: SingleColumn,
    props: {
      content: User
    }
  },
  {
    path: "/account/points",
    name: "pointsOverview",
    component: SingleColumn,
    props: {
      content: PointsOverview
    }
  },
  {
    path: "/account/stammdaten",
    name: "stammdaten",
    component: SingleColumn,
    props: {
      content: Stammdaten
    }
  },
  {
    path: "/account/postfach",
    name: "mailbox",
    component: SingleColumn,
    props: {
      content: Mailbox
    }
  },
];
