<template>
  <CategoryTop
    banner-image="preislisten/banner.jpg"
    title="Bridgestone Produkte"
    :breadcrumbs-parent-routes="['productInfo', 'produktbroschuren']"
    description="Laden Sie hier aktuelle Broschüren und Flyer zu unseren Bridgestone Reifen für Pkw, SUV und Transporter herunter."
  />

  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import FileItem from "../FileItem.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";

const files = computed(() => [
  {
    file: "broschuren/bridgestone/BS Blizzak LM005_Testergebnisse W22_2pager_Ansicht.pdf",
    name: "BLIZZAK LM005",
    description: "PKW, SUV - Winter",
    date: "Testergebnisse 2022",
    type: "pdf",
  },
  {
    file: "broschuren/bridgestone/BS Blizzak DM V3_2020_2pager_Ansicht.pdf",
    name: "BLIZZAK DM-V3",
    description: "SUV/4x4 - Winter",
    date: "Produktübersicht 2020",
    type: "pdf",
  },
  {
    file: "broschuren/bridgestone/BS Blizzak LM005_Testergebnisse W22_2pager_Ansicht.pdf",
    name: "DURAVIS ALL SEASON",
    description: "Transporter - All Season",
    date: "Produktübersicht 2020",
    type: "pdf",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Race_Dimensionen 2022_2pager_Ansicht.pdf",
    name: "POTENZA RACE",
    description: "PKW - Sommer Sport",
    date: "Produktübersicht 2020",
    type: "pdf",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Race_Testergebnis 2022_2pager_Ansicht.pdf",
    name: "POTENZA RACE",
    description: "PKW - Sommer Sport",
    date: "Testergebnisse 2022",
    type: "pdf",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Sport_2022_2pager_Ansicht.pdf",
    name: "POTENZA SPORT",
    description: "PKW, SUV - Sommer Sport",
    date: "Produktübersicht 2020",
    type: "pdf",
  },
  {
    file:
      "broschuren/bridgestone/BS Turanza T005 und Potenza Sport_Testergebnis S22_2pager_Ansicht.pdf",
    name: "TURANZA T005",
    description: "PKW, SUV - Sommer Sport",
    date: "Testergebnisse 2022",
    type: "pdf",
  },
]);
</script>

<style scoped lang="scss"></style>
