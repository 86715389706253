<template>
  <MDBRow>
    <MDBCol col="9" class="order-1" v-if="props.loader">
      <Loader :show="props.loader" :small="true" />
    </MDBCol>
    <MDBCol lg="9" class="order-2 order-lg-1" v-else>
      <button
        v-if="categories.length"
        type="button"
        @click="() => (categoriesOpen = !categoriesOpen)"
        class="d-block d-lg-none categories-toggle"
      >
        <MDBIcon icon="filter" size="1x" />
      </button>
      <MDBNavbar
        expand="lg"
        :class="`categories mb-5 d-lg-flex ${categoriesOpen ? 'd-block' : 'd-none'}`"
      >
        <MDBNavbarNav
          id="categoriesNavbar"
          collapse="categoriesNavbar"
          class="mb-2 mb-lg-0 align-items-stretch flex-wrap"
        >
          <MDBNavbarItem
            v-for="(category, i) in categories"
            :key="i"
            class="text-center categories-link-wrap mb-2"
          >
            <router-link
              :to="'/shop/categories/' + category.id"
              :class="`font-12 categories-link d-flex align-items-center ${
                category.id === route.params.id
                  ? 'categories-link--active text-white'
                  : 'text-dark'
              }`"
            >
              {{ category.name }}
            </router-link>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBNavbar>
    </MDBCol>
    <MDBCol lg="3" class="sort-select-wrap order-1 order-lg-2 mb-3 mb-lg-0">
      <select
        v-model="sortBy"
        class="sort-select font-12"
        @change="$emit('sortChanged', sortBy)"
      >
        <option value="lowest">Sortieren nach Niedrigster Preis</option>
        <option value="hightest">Sortieren nach Höchstem Preis</option>
      </select>
      <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, ref, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import Loader from "../General/Loader/index.vue";
defineEmits(["sortChanged"]);

const props = defineProps({
  loader: Boolean,
});
const store = useStore();
const route = useRoute();
const sortBy = ref("lowest");
const categoriesOpen = ref(false);

if (!store.getters.getCategories.length) {
  store.dispatch("loadCategories");
}

const categories = computed(() => {
  let currentCategory = null;
  let categories = [];

  if (store.getters.getCategories.length) {
    store.getters.getCategories.forEach((category) =>
      category.id === route.params.id ? (currentCategory = category) : null
    );
  }

  if (currentCategory) {
    categories = currentCategory.children;
  }

  return categories.filter(
    (cat) =>
      cat.active !== false && !["z0r91y9xeq", "EewYOAQW3D", "DqMZy791nm"].includes(cat.id)
  );
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.categories {
  padding-top: 0;
  box-shadow: 0 0 0 0 black;
}

.categories-link {
  background: $secondaryGray;
  padding: 10px;
  margin-right: 5px;
  min-height: 100%;
  line-height: 140%;
}
.categories-link-wrap:last-child {
  .categories-link {
    margin-right: 0px;
  }
}

.categories-toggle {
  margin-top: -43px;
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
  appearance: none;
  border: 0px solid $lightGray;
  padding: 0px 10px;
  font-size: 18px;
  background: transparent;
  color: $darkGray;
}

.categories-link--active {
  background: black;
}

.sort-select {
  border: 2px solid $secondaryGray;
  color: $lightGray;
  appearance: none;
  padding: 3px 40px 3px 10px;
  min-width: 240px;
  outline: none;

  @media screen and (max-width: $xxlBreakpoint) {
    min-width: 100px;
  }
}

.sort-select-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.sort-icon {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
