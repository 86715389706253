<template>
  <CategoryTop
    title="Bridgestone Prämienshop"
    banner-image="schulungen-banner.png"
    description="
    Herzlich willkommen im Prämienshop. Hier können Sie Ihre Bridgestone Plus Punkte <br/> 
    gegen Prämien aus verschiedenen Kategorien einlösen. Das Angebot an Produkten <br/> wird regelmäßig aktualisiert. Wir wünschen vielSpaß beim Stöbern."
  />

  <MDBRow>
    <MDBCol lg="4" class="mb-4" v-for="(category, i) in mainCategories" :key="i">
      <router-link
        :to="category.link"
        :style="`background-image: url(${background(
          category.image
        )}), linear-gradient(142deg, rgba(205, 205, 205, 1) 0%, rgba(97, 97, 97, 1) 100%)`"
        class="training text-center d-flex align-items-center justify-content-center"
      >
        <h3
          v-html="category.name"
          class="training-title font-40 font-lg-25 font-xl-30 font-xxl-40"
        ></h3>
      </router-link>
      <p v-html="category.description" class="py-4 px-3 font-16 hide-br-mobile"></p>
    </MDBCol>
  </MDBRow>

  <CategoryFilter
    :loader="categoriesRequestSent"
    @sort-changed="(sortValue) => (sortBy = sortValue)"
  />

  <ProductList
    :products="products"
    :loader="productRequestSent"
    @page-number-changed="(e) => pageNumberChanged(e)"
  />
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";

import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import CategoryFilter from "./CategoriesFilter.vue";
import ProductList from "./ProductList.vue";
import { getProducts } from "./products";

const store = useStore();
const sortBy = ref("lowest");
const background = (name) => require("../../assets/img/" + name);
const productRequestSent = computed(() => store.getters.getProductRequestSent);
const categoriesRequestSent = computed(() => store.getters.getCategoriesRequestSent);

store.dispatch("loadProducts", 1);

const pageNumberChanged = (e) => store.dispatch("loadProducts", e);
const products = computed(() => getProducts(sortBy));

const mainCategories = computed(() => [
  {
    name: "Werbemittel",
    image: "product-cat1.png",
    link: { name: "shopCategory", params: { id: "z0r91y9xeq" } },
    description:
      "Direkt hier auf der Plattform teilnehmen und <br/> 40 Extrapunkte für den Prämienclub sichern.",
  },
  {
    name: "Exklusiv-Prämien",
    image: "product-cat2.png",
    link: { name: "shopCategory", params: { id: "EewYOAQW3D" } },
    description:
      "Entdecken Sie das Pramienangebot in <br/>unserem Trainingscenter oder bei Ihnen vor Ort.",
  },
  {
    name: "Werkstatt-Tools",
    image: "product-cat3.png",
    link: { name: "shopCategory", params: { id: "DqMZy791nm" } },
    description:
      "Setzen Sie Ihre Punkte für Drittanbieter- <br/> Trainings ein und reduzieren Sie so Ihre Kosten.",
  },
]);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.training {
  min-height: 200px;
  background-position: center center;
}

.training-title {
  color: white;
}
</style>
