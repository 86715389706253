import TrainingsOverview from "@/components/Trainings/index.vue";
import SingleTraining from "@/components/Trainings/Single/index.vue";
import OnlineTrainings from "@/components/Trainings/Online/index.vue";
import SchulungenTrainings from "@/components/Trainings/Schulungen/index.vue";
import Default from "../views/Default.vue";
import Form from '@/components/Trainings/Form';

export default [
  {
    path: "/trainings",
    name: "trainings",
    component: Default,
    props: {
      content: TrainingsOverview,
      switchOrderMobile: true
    }
  },
  {
    path: "/online-trainings",
    name: "onlineTrainings",
    component: Default,
    props: {
      content: OnlineTrainings,
      switchOrderMobile: true
    }
  },
  {
    path: "/bridgestone-schulungen",
    name: "bridgestoneSchulungen",
    component: Default,
    props: {
      content: SchulungenTrainings,
      switchOrderMobile: true
    }
  },
  {
    path: "/training/:id",
    name: "training",
    component: Default,
    props: {
      content: SingleTraining,
      switchOrderMobile: true
    }
  },
  {
    path: "/training/:id/form",
    name: "trainingForm",
    component: Default,
    props: {
      content: Form,
      switchOrderMobile: true
    }
  },
];
