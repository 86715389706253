import { createStore } from 'vuex'
import { UserService } from '../services/user.service'
import router from '@/router'

export default createStore({
  state: {
    user: {},
    bankStatement: [],
    registerError: [],
    categories: [],
    products: [],
    productsMeta: {},
    productsPageNumber: 1,
    product: {},
    attribute_options: [],
    productRequestSent: false,
    categoriesRequestSent: false
  },
  mutations: {
    SET_REGISTER_ERRORS(state, payload) {
      state.registerError = payload
    },

    SET_USER(state, payload) {
      state.user = payload
    },

    SET_ATTRIBUTE_OPTIONS(state, payload) {

      if(payload.option == -1) {
        const index = state.attribute_options.filter(v => v.attribute === payload.attribute)

        state.attribute_options.splice(index, 1)
      } else {
        state.attribute_options.push(payload)
      }
    },

    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },

    SET_PRODUCTS(state, payload) {
      state.products = payload
    },

    SET_PRODUCTS_META(state, payload) {
      state.productsMeta = payload
    },

    SET_PRODUCTS_PAGE_NUMBER(state, payload) {
      state.productsPageNumber = payload
    },

    SET_PRODUCT(state, payload) {
      state.product = payload
    },

    SET_BANK_STATEMENT(state, payload) {
      state.bankStatement = payload
    },
    SET_PRODUCT_REQUEST_SENT(state, payload) {
      state.productRequestSent = payload
    },
    SET_CATEGORIES_REQUEST_SENT(state, payload) {
      state.categoriesRequestSent = payload
    }
  },
  actions: {
    async loginUser({ commit }, userData) {
      const response = await UserService.login(
        userData.email,
        userData.password,
      )
      if (response !== undefined) {
        await router.push('/dashboard')
      } else if (response.errors !== undefined) {
        commit('SET_REGISTER_ERRORS', response)
      }
    },

    async me({ commit }) {
      try {
        const response = await UserService.me()
        // console.log(response)
        // const userData = await UserService.userData(response.data.data.id);
        // console.log(userData)
        commit('SET_USER', response.data.data)
      } catch (err) {
        router.push('/')
      }
    },

    async bankStatement({ commit }) {
      try {
        const response = await UserService.bankStatement()
        commit('SET_BANK_STATEMENT', response.data)
      } catch (err) {
        router.push('/')
      }
    },

    async loadCategories({ commit }) {
      try {
        commit('SET_CATEGORIES_REQUEST_SENT', true)
        const response = await UserService.categories()
        commit('SET_CATEGORIES', response.data.data)
        commit('SET_CATEGORIES_REQUEST_SENT', false)
      } catch (err) {
        router.push('/')
      }
    },

    async loadProducts({ commit }, page) {
      try {
        commit('SET_PRODUCT_REQUEST_SENT', true)
        const response = await UserService.products(page)
        commit('SET_PRODUCTS', response.data.data)
        commit('SET_PRODUCTS_META', response.data.meta)
        commit('SET_PRODUCT_REQUEST_SENT', false)
      } catch (err) {
        router.push('/')
      }
    },

    async addItem({ getters, state }, data) {
      try {
        const token = getters.getCart?.token
        const product = { ...data, token }

        if(state.attribute_options) {
          const attributeData = {
            attributes: state.attribute_options
          }
          const productData = {...product, ...attributeData}
          await UserService.addItem(productData)
        } else {
          await UserService.addItem(product)
        }
      } catch (err) {
        console.log(err)
      }
    },

    async loadProductById({ commit }, id) {
      try {
        const response = await UserService.product(id)
        commit('SET_PRODUCT', response.data.data)
      } catch (err) {
        router.push('/')
      }
    },

    async loadCategoryProduct({ commit }, id, pageNumber) {
      try {
        commit('SET_PRODUCT_REQUEST_SENT', true)
        const response = await UserService.categoryProducts(id, pageNumber)
        commit('SET_PRODUCTS', response.data.data)
        commit('SET_PRODUCTS_META', response.data.meta)
        commit('SET_PRODUCT_REQUEST_SENT', false)
      } catch (err) {
        router.push('/')
      }
    },


    setAttributeOption({ commit }, attributeOption) {
      commit('SET_ATTRIBUTE_OPTIONS', attributeOption)
    },

    async deleteItem({ dispatch }, token) {
      try {
        await UserService.deleteCartItem(token)
        dispatch('me')

      } catch (err) {
        router.push('/')
      }
    },

    // eslint-disable-next-line no-unused-vars
    async createOrder({ commit }, data) {
      try {
       const response =  await UserService.createOrder(data)

        if(response.data.data.success === true) {
          router.push('/order/complete')
        }

      } catch (err) {
        router.push('/')
      }
    },

    clearAttributes({  state }) {
      state.attribute_options = []
    }
  },

  getters: {
    getMe: (state) => state.user,
    getBankStatementHeadersStandard: (state) => state.bankStatement.columns?.tableHeaders,
    getBankStatementHeadersBoosters: (state) => state.bankStatement.columns?.boosters,
    getBankStatementHeadersAdditional: (state) => state.bankStatement.columns?.all,
    getPoints: (state) => state.bankStatement.columns?.allPoints,
    getBankStatementData: (state) => state.bankStatement.data,
    getCategories: (state)  => state.categories,
    getProducts: (state)  => state.products,
    getProductsMeta: (state)  => state.productsMeta,
    getProductsPageNumber: (state) => state.productsPageNumber,
    getProduct: (state)  => state.product,
    getCart: (state) => state.user?.cart,
    getProductRequestSent: (state) => state.productRequestSent,
    getCategoriesRequestSent: (state) => state.categoriesRequestSent
  },

  modules: {},
})
