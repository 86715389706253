<template>
  <CategoryTop
    banner-image="trainings-overview.jpg"
    title="Mein Konto"
    description="Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Stammdaten ergänzen oder <br/> anpassen, Ihr Postfach und Ihren Punkte-Kontosauszug einsehen."
  />

  <MDBRow>
    <MDBCol lg="4" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center p-3"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-30 font-xxl-40"
        ></h3>
      </router-link>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

const trainings = computed(() => [
  {
    name: "Stammdaten",
    link: { name: "stammdaten" },
  },
  {
    name: "Postfach",
    link: { name: "mailbox" },
  },
  {
    name: "Kontoauszug",
    link: { name: "pointsOverview" },
  },
]);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.training {
  background: linear-gradient(
    142deg,
    rgba(205, 205, 205, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  min-height: 200px;
}

.training-title {
  color: white;
}
</style>
