<template>
  <div class="mb-4 bg-white mt-3">
    <MDBNavbar class="d-flex p-0">
      <MDBNavbarNav :class="`${menuOpen ? 'col-9' : 'col-3'} col-lg-3 d-flex flex-row`">
        <MDBNavbarItem linkClass="py-0 p-relative" class="">
          <MDBNavbarToggler target="#navbarSupportedContent" @click="toggleMenu" />
          <div v-if="menuOpen" class="menu-wrap-bg" @click="toggleMenu"></div>
          <div v-if="menuOpen" @click="toggleMenu" class="menu-wrap">
            <MDBNavbarNav class="mb-5">
              <h3 class="fw-bold pb-2">
                <router-link class="text-dark" :to="{ name: 'account' }">
                  Mein Konto
                </router-link>
              </h3>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'stammdaten' }">
                  Stammdaten
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'pointsOverview' }">
                  Kontoauszug
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'mailbox' }">
                  Postfach
                </router-link>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <MDBNavbarNav class="mb-5">
              <h3 class="fw-bold pb-2">
                Bridgestone <span class="text-danger">Plus</span>
              </h3>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" to="/"> Über BS Plus </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" to="/">
                  Häufig gestellte Fragen
                </router-link>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <MDBNavbarNav class="mb-5">
              <MDBNavbarItem>
                <h3 class="fw-bold m-0 pb-2">
                  <router-link class="text-dark" to="/shop">
                    TYRE<span class="text-danger">CLUB</span>
                  </router-link>
                </h3>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <MDBNavbarNav class="mb-5">
              <h3 class="fw-bold pb-2">
                <router-link to="/trainings" class="text-dark">
                  Trainings & Schulungen
                </router-link>
              </h3>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" to="/online-trainings">
                  Online-Trainings
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" to="/bridgestone-schulungen">
                  Bridgestone Schulungen
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" to="/">
                  Externe Schulungen
                </router-link>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <MDBNavbarNav>
              <h3 class="fw-bold pb-2">
                <router-link class="text-dark" :to="{ name: 'productInfo' }">
                  Produkte, Preislisten und mehr
                </router-link>
              </h3>
              <MDBNavbarItem>
                <router-link
                  class="font-18 text-dark"
                  :to="{ name: 'produktbroschuren' }"
                >
                  Produktbroschüren
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'preislisten' }">
                  Preislisten
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'pricat' }">
                  Pricat
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link class="font-18 text-dark" :to="{ name: 'bDrive' }">
                  B-DRIVE
                </router-link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <router-link
                  class="font-18 text-dark"
                  :to="{ name: 'beanstandungsFormular' }"
                >
                  Beanstandungs-Formular
                </router-link>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </div>
        </MDBNavbarItem>
        <MDBNavbarItem :class="`${menuOpen ? '' : 'd-none'} d-lg-block`">
          <router-link to="/dashboard" @click="() => (menuOpen = false)">
            <img class="menu-logo" src="../../assets/img/logo.png" alt="logo" />
          </router-link>
        </MDBNavbarItem>
      </MDBNavbarNav>
      <MDBNavbarNav
        :class="`justify-content-center w-100 col flex-row d-lg-flex ${
          menuOpen ? 'd-none' : 'd-flex'
        }`"
      >
        <MDBNavbarItem class="w-100 text-center font-14 font-md-16 font-lg-18 fw-bold">
          <span>{{ props.title ? props.title : $t("pageName." + currentRoute) }}</span>
        </MDBNavbarItem>
      </MDBNavbarNav>
      <MDBNavbarNav
        class="col-3 d-flex flex-row justify-content-end align-items-center pe-lg-3 pe-1"
      >
        <MDBNavbarItem>
          <router-link
            :to="{ name: 'mailbox' }"
            class="nav-link px-lg-3 px-1 px-sm-2 py-0"
          >
            <img src="../../assets/img/icons/envelope.svg" alt="envelope" />
          </router-link>
        </MDBNavbarItem>
        <MDBNavbarItem linkClass="px-lg-3 px-1 px-sm-2 py-0" href="#">
          <img src="../../assets/img/icons/alert.svg" alt="alert" />
        </MDBNavbarItem>
        <MDBNavbarItem
          class="menu-item-border"
          linkClass="px-lg-3 px-1 px-sm-2 py-0"
          href="#"
        >
          <img src="../../assets/img/icons/notification.svg" alt="notification" />
        </MDBNavbarItem>
        <MDBNavbarItem class="menu-item-rounded" linkClass="py-0" href="#">
          <img src="../../assets/img/icons/question-mark.svg" alt="help" />
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBNavbar>
  </div>
</template>

<script setup>
import { MDBNavbar, MDBNavbarNav, MDBNavbarToggler, MDBNavbarItem } from "mdb-vue-ui-kit";
import { useRoute } from "vue-router";
import { computed, ref, defineProps } from "vue";

const menuOpen = ref(false);
const currentRoute = computed(() => {
  return useRoute().name;
});

const props = defineProps({
  title: String,
});

const toggleMenu = () => (menuOpen.value = !menuOpen.value);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.menu-logo {
  padding-left: 15px;
  margin-left: 5px;
  position: relative;
  top: 0px;
  border-left: 1px solid $borderLighGray;

  @media screen and (max-width: $lgBreakpoint) {
    padding-left: 0;
    margin-left: 0;
    border-left: 0px solid white;
    width: 130px;
    position: relative;
    z-index: 9999;
  }
}

.menu-item-rounded {
  background: $backgroundGray;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 5px 0.5rem;
  font-size: 12px;
  font-weight: bold;

  @media screen and (max-width: $lgBreakpoint) {
    background: transparent;
    margin-left: 0;
    margin-right: 0.5rem;
    width: 15px;
  }

  @media screen and (max-width: $smBreakpoint) {
    margin-right: 0.2rem;
    width: auto;
  }

  &--dark {
    background: $darkGray;
    color: white;

    span {
      color: white;
    }
  }
}

.menu-item-border {
  border-right: 1px solid $borderLighGray;
  margin-right: 0.5rem;

  @media screen and (max-width: $smBreakpoint) {
    margin-right: 5%;
    padding-right: 2%;
  }
}

.menu-wrap {
  position: absolute;
  left: 0;
  top: 42px;
  width: 512px;
  padding: 100px;
  background: white;
  z-index: 9999;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: $lgBreakpoint) {
    padding: 2rem;
    max-width: 100%;
  }
}

.menu-wrap-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}
</style>
