<template>
  <MDBRow class="mb-5">
    <MDBCol :class="`${isWrong ? 'wrong-question' : ''}`">
      <h6 class="mb-3">{{ props.question.label }}</h6>
      <div
        v-for="(answer, key) in props.question.answers"
        :key="key"
        class="form-check mb-1 d-flex"
      >
        <input
          class="form-check-input"
          type="checkbox"
          :name="`${question.id}-${key}`"
          :id="`${question.id}-${key}`"
          @change="checkCheckbox(key)"
        />
        <label class="form-check-label" :for="`${question.id}-${key}`">
          {{ answer.label }}
        </label>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { defineProps } from "vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { ref, watch } from "vue";

const checked = ref([]);
const isWrong = ref(false);

const props = defineProps({
  question: Object,
  validate: Number,
});

const checkCheckbox = (key) => {
  if (checked.value.includes(key)) {
    checked.value = checked.value.filter((val) => val !== key);
  } else {
    checked.value.push(key);
  }
};

watch(props, () => {
  if (props.question.doNotValidate) {
    isWrong.value = false;
    return;
  }

  if (checked.value.length === 0 && props.validate) {
    isWrong.value = true;
  } else {
    let validationNotPassed = false;

    props.question.answers.forEach((answer, key) => {
      if (
        (answer.correct && !checked.value.includes(key)) ||
        (!answer.correct && checked.value.includes(key))
      ) {
        validationNotPassed = true;
      }
    });

    isWrong.value = validationNotPassed;
  }
});
</script>

<style scoped lang="scss">
@import "../../../../styles/variables";

.wrong-question {
  background: lighten($dangerRed, 40%);
}
</style>
