<template>
  <div
    class="banner mb-4 d-flex align-items-end py-5 px-4 bg-bottom-right-mobile"
    :style="style"
  >
    <h2 v-if="bannerTitle" v-html="bannerTitle" class="banner-title font-40 m-0"></h2>
  </div>
  <Breadcrumbs
    :parent-routes="breadcrumbsParentRoutes"
    :routeName="props.breadcrumbsRouteName"
  />
  <div v-if="props.loader">
    <Loader :show="props.loader"></Loader>
  </div>
  <MDBRow v-else>
    <MDBCol :lg="showRight() ? '9' : '12'">
      <h1 class="my-3">{{ props.title }}</h1>
      <p
        :class="`font-16 description ${props.small ? 'no-height mb-5' : ''}`"
        v-html="props.description"
      ></p>
    </MDBCol>
    <MDBCol v-if="showRight()" col="3"> <slot name="right" /> </MDBCol>
  </MDBRow>
</template>

<script setup>
import Breadcrumbs from "../../General/Breadcrumbs/index";
import { defineProps, useSlots } from "vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Loader from "../Loader/index.vue";

const props = defineProps({
  title: String,
  description: String,
  small: Boolean,
  bannerImage: String,
  bannerTitle: String,
  breadcrumbsParentRoutes: Array,
  breadcrumbsRouteName: String,
  loader: Boolean,
});

const slots = useSlots();
const showRight = () => !!slots.right;

let style = {};

if (props.bannerImage) {
  const image = require("../../../assets/img/" + props.bannerImage);

  style = {
    backgroundImage: `url('${image}')`,
  };
}
</script>

<style scoped lang="scss">
@import "../../../styles/variables";
.banner {
  min-height: 282px;
  background: $accentBlue;
  background-size: cover;
  background-position: right bottom;

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 165px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 106px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 18vw;
  }
}

.description {
  min-height: 160px;

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 80px;
  }

  &.no-height {
    min-height: auto;
  }
}

.banner-title {
  color: white;
  text-shadow: 5px 5px 20px rgb(0 0 0 / 70%);
}
</style>
