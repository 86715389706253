import Product from "@/components/Shop/Product.vue";
import Default from "../views/Default.vue";
import Category from '@/components/Shop/Category.vue';
import ShopIndex from '@/components/Shop/index.vue';

export default [
  {
    path: "/shop",
    name: "shop",
    component: Default,
    props: {
      content: ShopIndex,
      switchOrderMobile: true
    }
  },
  {
    path: "/shop/products",
    name: "shopProducts",
    component: Default,
    props: {
      content: Category,
      switchOrderMobile: true
    }
  },
  {
    path: "/shop/cart",
    name: "Warenkorb",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
  },
  {
    path: "/shop/order/complete",
    name: "Order Complete",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Order.vue"),
  },
  {
    path: "/shop/categories/:id",
    name: "shopCategory",
    component: Default,
    props: {
      content: Category,
      switchOrderMobile: true
    }
  },
  {
    path: "/shop/products/:id/detail",
    name: "product",
    component: Default,
    props: {
      content: Product,
      switchOrderMobile: true
    }
  },
];
